import React from "react";
import { Button } from "@mui/material";
import styled from "styled-components";
import MainButton from "../../styles/Button";

const StyledButton = styled(Button)`
  ${MainButton}
  height: 2.3rem;
  &.MuiButtonBase-root {
    margin-right: 1rem;
  }
`;

export default function EditButton({
  onClick,
  text,
}: {
  onClick: any;
  text: string;
}) {
  return (
    <StyledButton variant="contained" onClick={onClick}>
      {text}
    </StyledButton>
  );
}
