import React, { ReactElement } from 'react';
import styled from 'styled-components';

export interface ServiceProps {
    img: string;
    title: string;
    content: ReactElement;
}

const StyledService = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;

    img {
        border: none;
        margin: 0;
        padding: 0;
        line-height: 0;
        font-size: 0;
        margin-bottom: 35px;
    }
    p {
        font-size: 22px;
        color: #fff;
        font-weight: 600;
        display: block;
        text-align: center;
    }
    span {
        font-size: 15px;
        color: #fff;
        display: block;
        line-height: 1.8;
        padding-top: 10px;
    }
`;

const HorizService = ({ img, title, content }: ServiceProps) => (
    <StyledService>
        <img src={img} alt="HorizService image" />
        <p>
            {title}
            <span>{content}</span>
        </p>
    </StyledService>
);

export default HorizService;
