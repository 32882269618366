import React from 'react';
import HGroup from '../components/common/HGroup';
import Location from '../components/common/Location';
import PageTemplate from '../templates/PageTemplate';

const ReaviewPage = (props: any) => (
    <PageTemplate>
        <Location path={['Home', 'Gallery']} />
        <HGroup h3="review" p="Premium ART Rental Service" />
    </PageTemplate>
);

export default ReaviewPage;
