import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ContentsWrap from '../../components/common/ContentsWrap';

const StyledTabMenu = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    li {
        max-width: 323px;
        width: 100%;
        height: 118px;
        text-align: center;
        background-color: #fafafa;
        border: 2px solid #373737;
        margin-left: 19px;
        margin-bottom: 25px;
        padding: 0 20px;
    }
    li:fist-child {
        margin-left: 0px;
    }
    p {
        font-size: 18px;
        font-weight: 600;
        color: #000;
        text-align: center;
        padding-top: 18px;
        line-height: 2.4;
    }
    span {
        font-size: 12px;
        color: #818181;
        font-weight: 400;
        text-align: center;
        display: block;
        border-top: 1px solid #686868;
    }
    .selected {
        background-color: #3c3c3c;
        border: 2px solid #000;
    }
`;

interface TabMenuProps {
    serviceName: string;
}

const TabMenu = ({ serviceName }: TabMenuProps) => (
    <ContentsWrap>
        <StyledTabMenu>
            <li className={serviceName === 'guide' ? 'selected' : ''}>
                <Link to="/service/guide">
                    <p>
                        이용방법 / 요금안내
                        <span>How to Use / Rental Rate Information</span>
                    </p>
                </Link>
            </li>
            <li className={serviceName === 'apply_write' ? 'selected' : ''}>
                <Link to="/service/apply_write">
                    <p>
                        렌탈 / 구매 신청하기
                        <span>Rental / Purchase Apply</span>
                    </p>
                </Link>
            </li>
            <li className={serviceName === 'curator' ? 'selected' : ''}>
                <Link to="/service/curator">
                    <p>
                        큐레이터 상담하기
                        <span>Curator Consultation Request</span>
                    </p>
                </Link>
            </li>
        </StyledTabMenu>
    </ContentsWrap>
);

export default TabMenu;
