import React from 'react';
import styled from 'styled-components';
import Footer from '../components/common/Footer';
import Header from '../components/common/Header';

interface PageTemplateProps {
    children?: React.ReactNode | React.ReactNode[];
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`;

const PageTemplate = ({ children }: PageTemplateProps) => (
    <Wrapper>
        <Header />
        {children}
        <Footer />
    </Wrapper>
);

export default PageTemplate;
