import React from 'react';
import styled from 'styled-components';
import InsertTitle from './InsertTitle';
import VertService from './VertService';
import VertServices from './VertServices';
import services_icon04 from '../../assets/about/services_icon04.gif';
import services_icon05 from '../../assets/about/services_icon05.gif';
import services_icon06 from '../../assets/about/services_icon06.gif';
import ContentsWrap from '../common/ContentsWrap';

const VertServicesWrap = styled.div`
    max-width: 1140px;
    margin: 0 auto;
    padding-bottom: 40px;
`;

const VertServicesSection = () => (
    <ContentsWrap>
        <VertServicesWrap>
            <InsertTitle text="스퀘어팩토리 서비스" />
            <VertServices>
                <VertService
                    img={services_icon04}
                    title="미술품 대여"
                    content={
                        <>
                            마음에 드는 미술품을 빌려 드리겠습니다.
                            <br />
                            필요한 시기에 원하는 곳에 설치전문가가 직접 디피하여
                            드립니다.
                            <br />
                            장기 렌탈의 경우 3개월 마다 주기적으로 새로운
                            작품으로 교체하여 드립니다.
                        </>
                    }
                />
                <VertService
                    img={services_icon05}
                    title="미술품 판매"
                    content={
                        <>
                            진품을 소장하는 기회를 가지세요.
                            <br />
                            시간이 갈수록 가치를 더하는 예술품에 투자하실 수
                            있습니다.
                        </>
                    }
                />
                <VertService
                    img={services_icon06}
                    title="미술품 이해"
                    content={
                        <>
                            친절한 설명과 전문지식으로 미술작품에 친근하게
                            다가갈 수 있습니다.
                            <br />
                            개인 공간 및 기업, 병원, 호텔, 관공서에 작품을
                            설치하여 드리고
                            <br />
                            아트클래스를 통하여 예술품을 보는 안목을 선물하여
                            드립니다.
                        </>
                    }
                />
            </VertServices>
        </VertServicesWrap>
    </ContentsWrap>
);

export default VertServicesSection;
