import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import hlogoGif from "../../assets/common/h1_logo.jpeg";
import { Link } from "react-router-dom";
import { Drawer, IconButton } from "@mui/material";
import { mediaQuery } from "../../styles/media";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth } from "../../hooks/useAuth";

interface HeaderProps {
  window?: () => Window;
}

const StyledHeader = styled.header`
  width: 100%;
  height: 79px;
  background: #fff;
  border-bottom: 1px solid #f6f6f6;
  padding: 1rem;
`;

const HeaderContents = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  max-width: 1140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledUl = styled.ul`
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  a {
    color: inherit;
    text-decoration: none;
  }
  ${mediaQuery(900)} {
    display: none;
  }
`;

const StyledLi = styled.li`
  text-transform: uppercase;
  font-size: 16px;
  padding-left: 45px;
  font-weight: 700;
  ${mediaQuery(900)} {
    padding-left: 0;
    flex-grow: 1;
  }
`;

const DrawerNav = styled.ul`
  padding: 28px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  a {
    flex-grow: 1;
    max-height: 80px;
  }
  overflow-y: scroll;
`;

const drawerWidth = 240;

const navigationItems = [
  {
    to: "/about",
    text: "회사소개",
  },
  {
    to: "/gallery",
    text: "갤러리",
  },
  // {
  //   to: "/service/guide",
  //   text: "렌탈/구매",
  // },
  // {
  //   to: "/service/curator",
  //   text: "큐레이터상담",
  // },
  {
    to: "/review",
    text: "설치사례",
  },
  // {
  //   to: "/contact",
  //   text: "문의하기",
  // },
  {
    to: "/login",
    text: "로그인",
  },
  {
    to: "/logout",
    text: "로그아웃",
  },
  {
    to: "/dashboard",
    text: "관리자모드",
  },
];

const Header = (props: HeaderProps) => {
  const { user } = useAuth();

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = useCallback((mobileOpen: boolean) => {
    setMobileOpen(!mobileOpen);
  }, []);

  const container =
    window !== undefined
      ? () => window().document.body
      : // undefined
        document.querySelector("body");

  const Navigation = useMemo(
    () => (
      <DrawerNav>
        {navigationItems.map(({ to, text }) => {
          if (
            (user && to === "/login") ||
            (!user && to === "/logout") ||
            (!user && to === "/dashboard")
          ) {
            return;
          } else {
            return (
              <Link to={to} key={Math.random()}>
                <StyledLi>{text}</StyledLi>
              </Link>
            );
          }
        })}
      </DrawerNav>
    ),
    [user]
  );

  return (
    <StyledHeader>
      <HeaderContents>
        <div style={{ width: "134px", height: "60px" }}>
          <Link to="/">
            <img
              src={hlogoGif}
              alt="logo"
              style={{ width: "100%", height: "100%" }}
            />
          </Link>
        </div>
        <nav>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => handleDrawerToggle(mobileOpen)}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <StyledUl className="test">
            {navigationItems.map(({ to, text }) => {
              if (
                (user && to === "/login") ||
                (!user && to === "/logout") ||
                (!user && to === "/dashboard")
              ) {
                return;
              } else {
                return (
                  <Link to={to} key={Math.random()}>
                    <StyledLi>{text}</StyledLi>
                  </Link>
                );
              }
            })}
          </StyledUl>
        </nav>
      </HeaderContents>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: {
            md: "none",
          },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {Navigation}
      </Drawer>
    </StyledHeader>
  );
};

export default Header;
