import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { createArt, getAllArts, updateArt } from "../../apis/gallery";
import { artForm, artList } from "../../recoil/state";
import { IArt } from "../../types/state";
import { createFormData, IFormObj } from "../../util/util";
import ArtForm from "./ArtForm";

const ArtEditPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const artId = searchParams.get("id");
  const [arts, setArts] = useRecoilState(artList);

  const art: IArt = useMemo(() => {
    const selectedArt = arts.find(
      ({ id }) => id && id.toString() === artId?.toString()
    );
    if (artId != null && selectedArt) {
      return selectedArt;
    } else {
      return artForm;
    }
  }, [artId]);

  const handleCreateArt = async (newArt: IFormObj) => {
    try {
      const newArtFormData = createFormData(newArt);
      createArt(newArtFormData);
      window.alert("게시물을 성공적으로 등록했습니다.");
    } catch (e) {
      window.alert("게시물 등록에 실패했습니다.");
    }

    try {
      const newArts = await getAllArts();
      setArts(newArts);
    } catch (e) {
      window.alert("게시물을 불러오지 못했습니다.");
      return;
    }
  };

  const handleUpdateArt = async (id: number, updatedArt: IFormObj) => {
    try {
      const newArtFormData = createFormData(updatedArt);
      await updateArt(id.toString(), newArtFormData);
      const newArts = await getAllArts();
      setArts(newArts);
      window.alert("게시물을 성공적으로 수정했습니다.");
    } catch (e) {
      window.alert("게시물 수정에 실패했습니다.");
    }
  };

  return (
    <ArtForm
      art={art}
      onCreateArt={handleCreateArt}
      onUpdateArt={handleUpdateArt}
    />
  );
};

export default ArtEditPage;
