import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ArtEditPage from "../components/dashboard/ArtEditPage";
import { useAuth } from "../hooks/useAuth";
import DashboardPage from "../pages/DashboardPage";

export const AuthorizedRoute = () => {
  const { user } = useAuth();
  if (!user || user.role !== "ADMIN") {
    return <Navigate to="/" />;
  }
  return (
    <Routes>
      <Route path="/" element={<DashboardPage />} />
      <Route path="edit" element={<ArtEditPage />} />
      <Route path="*" element={<Navigate replace to="/dashboard" />} />
    </Routes>
  );
};
