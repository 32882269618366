import React, { JSXElementConstructor, ReactElement, useCallback } from "react";
import { MeshProps, useLoader } from "@react-three/fiber";
import { DoubleSide, Object3D, Texture, TextureLoader } from "three";

interface ImageGeoProps extends MeshProps {
  imageUrl: string;
  callbackRef: (element: Object3D | null) => void;
}
/**
 * @param {string} imageUrl 이미지 경로는 public 폴더 기준
 */
const ImageGeo = ({
  imageUrl,
  position,
  scale,
  rotation,
  callbackRef,
}: ImageGeoProps) => {
  const Mesh = useCallback((): ReactElement<
    any,
    string | JSXElementConstructor<any>
  > => {
    const image = useLoader(TextureLoader, `${imageUrl}`);
    return (
      <mesh
        ref={callbackRef}
        position={position}
        scale={scale}
        rotation={rotation}
      >
        {/* width * 1.55, height * 1.55 */}
        <planeGeometry attach="geometry" args={[0.35 * 1.55, 0.24 * 1.55]} />
        <meshBasicMaterial
          attach="material"
          map={image as Texture}
          side={DoubleSide}
        />
      </mesh>
    );
  }, [imageUrl, position, scale, rotation]);

  return <Mesh />;
};

ImageGeo.displayName = "ImageGeo";

export default ImageGeo;
