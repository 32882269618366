import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { artList } from "../../recoil/state";
import { IArt } from "../../types/state";
import CardList from "./CardList";
import Button from "./Button";
import { ButtonWrapper } from "./ArtForm";
import { useNavigate } from "react-router-dom";
import { deleteArt, getAllArts } from "../../apis/gallery";

export interface AdminHomeProps {}

const AdminHome = () => {
  const [deleteTarget, setSelected] = useState<number[]>([]);
  const [arts, setArts] = useRecoilState<IArt[]>(artList);
  const navigate = useNavigate();

  const handleToggle = (value: number) => () => {
    const currentIndex = deleteTarget.indexOf(value);
    const newSelected = [...deleteTarget];
    if (currentIndex === -1) {
      newSelected.push(value);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
  };

  const handleDeleteArts = async (idArray: number[]) => {
    try {
      await deleteArt(idArray);
      window.alert("게시물을 성공적으로 삭제했습니다.");
    } catch (e) {
      console.log(e);
      window.alert("게시물 삭제에 실패했습니다.");
    }

    try {
      const newArts = await getAllArts();
      setArts(newArts);
    } catch (e) {
      console.log(e);
      window.alert("페이지를 새로고침 해주세요.");
      return;
    }
  };

  const handleClickCreateBtn = () => {
    navigate({ pathname: "edit", search: `?id=null` });
  };

  const handleClickDeleteBtn = () => {
    if (deleteTarget.length === 0) {
      window.alert("삭제할 작품을 선택해주세요.");
      return;
    }
    handleDeleteArts([...deleteTarget]);
  };

  return (
    <>
      <CardList
        selected={deleteTarget}
        artData={arts}
        handleToggle={handleToggle}
      />
      <ButtonWrapper>
        <Button onClick={handleClickCreateBtn} text={"추가"} />
        <Button onClick={handleClickDeleteBtn} text={"삭제"} />
      </ButtonWrapper>
    </>
  );
};

export default AdminHome;
