const MainButton = `
  background-color: #E61780;
  color: #FFF;
  font-size: 15px;
  font-weight: bold;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  opacity: 0.8;

  width: 56px;
  height: 56px;
  margin-bottom: 15px;
`;

export default MainButton;
