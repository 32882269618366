import React from 'react';
import styled from 'styled-components';

interface PrivacyPolicyProps {
    fullWidth: boolean;
}

const StyledPrivacyPolicy = styled.div<PrivacyPolicyProps>`
    ${(props) =>
        !props.fullWidth
            ? `.agree_wrap {
                width: 353px;
                margin: 30px 0;
            }`
            : `.agree_wrap {
                width: 100%;
                margin-top: 30px;
                margin: 30px 0;
            }`}

    .agree_wrap > p.title {
        font-size: 16px;
        font-weight: 600;
        color: #555;
    }
    .agree_wrap > .agree_cont {
        border: 1px solid #e2e2e2;
        background-color: #f3f3f3;
        margin-top: 10px;
        padding: 0 15px 20px 15px;
    }
    .agree_wrap > .agree_cont > p.title {
        font-size: 14px;
        font-weight: 600;
        padding-top: 20px;
    }
    .mt10 {
        margin-top: 10px;
    }
    .pl5 {
        padding-left: 5px;
    }
`;

const PrivacyPolicy = ({ fullWidth }: PrivacyPolicyProps) => (
    <StyledPrivacyPolicy fullWidth={fullWidth}>
        <div className="agree_wrap">
            <p className="title">ㆍ개인정보 수집 이용 및 동의</p>
            <div className="agree_cont">
                <p className="title">- 수집하는 개인정보의 항목</p>
                <p>
                    필수사항 : 이름, 기업명, 지역, 이메일, 전화번호
                    <br />
                    선택사항 : 작품 스타일 내용
                </p>
                <p className="title">- 개인정보 수집 이용 목적</p>
                <p>
                    견적 및 기타 문의사항 응답 및 이와 관련한
                    <br />
                    고객연락 업무 / 기타 원활한 양질의 서비스 제공 등
                </p>
                <p className="title">- 개인정보 보유 및 이용기간</p>
                <p>
                    개인정보는 수집 및 이용목적 달성 시까지 보유하며,
                    <br />
                    개인정보의 수집 및 이용목적이 달성되면 지체없이 파기함
                </p>
            </div>
            <p className="mt10">
                <input type="checkbox" name="" id="agree_check" />
                <label htmlFor="agree_check" className="pl5">
                    개인정보 수집 이용에 동의합니다.
                </label>
            </p>
        </div>
    </StyledPrivacyPolicy>
);

export default PrivacyPolicy;
