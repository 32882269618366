import React from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { hitTestMode, customMesh, imageMeshDelta } from "../../recoil/state";
import MainButton from "../../styles/Button";
import { updateClassList } from "../../util/element";

const ResetButton = () => {
  const setHitTest = useSetRecoilState(hitTestMode);
  const resetCustomMesh = useResetRecoilState(customMesh);
  const resetImageMeshDelta = useResetRecoilState(imageMeshDelta);

  const handleClick = () => {
    setHitTest(true);
    resetCustomMesh();
    resetImageMeshDelta();
    updateClassList(".reset-button", "visible", "REMOVE");
    updateClassList(".reset-button", "invisible", "ADD");
    updateClassList(".arrow-keys", "visible", "REMOVE");
    updateClassList(".arrow-keys", "invisible", "ADD");
    // updateClassList(".fix-button", "invisible", "REMOVE");
    // updateClassList(".fix-button", "visible", "ADD");
  };

  return (
    <div className={"reset-button invisible"}>
      <FixButtonComponent onClick={handleClick}>초기화</FixButtonComponent>
    </div>
  );
};

const FixButtonComponent = styled.button`
  ${MainButton}
`;

export default ResetButton;
