import React, { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { client } from "../apis/gallery";
import { useLocalStorage } from "./useLocalStorage";

interface User {
  [key: string]: any;
}

interface AuthContextValue {
  user: User | null;
  login: (data: User) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextValue>({
  user: null,
  login: () => {},
  logout: () => {},
});

interface Props {
  children: React.ReactNode;
}

interface User {
  id: string;
  password: string;
}

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data: any) => {
    try {
      const response = await client.post("/auth/login/", data);
      setUser(response.data.user);
      navigate("/");
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.status === 401) {
        alert("존재하지 않는 회원입니다.");
      } else {
        alert("존재하지 않는 회원입니다.");
      }
    }
  };

  // call this function to sign out logged in user
  const logout = async () => {
    try {
      setUser(null);
      navigate("/", { replace: true });
    } catch (error) {
      console.error(error);
      alert("로그아웃 도중에 문제가 발생하였습니다.");
    }
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
