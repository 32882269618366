import React from 'react';
import styled from 'styled-components';
import bg_review_title from '../../assets/main/bg_review_title.gif';

interface InsertTitleProps extends StyeldContentsProps {
    text: string;
}

interface StyeldContentsProps {
    backgroundColor?: string;
}

const StyeldContents = styled.div<StyeldContentsProps>`
    .title {
        height: 30px;
        line-height: 30px;
        text-transform: uppercase;
        font-size: 30px;
        text-align: center;
        font-weight: 500;
        color: #ed1a3b;
        letter-spacing: -0.5px;
        ${(props) =>
            props.backgroundColor
                ? `background-color: ${props.backgroundColor}`
                : `background: url(${bg_review_title}) repeat-x left center`};
    }
    .title > span {
        background-color: ${(props) =>
            props.backgroundColor ? props.backgroundColor : '#fafafa'};
        padding: 0 30px;
    }
`;

const InsertTitle = ({ text, backgroundColor }: InsertTitleProps) => (
    <StyeldContents backgroundColor={backgroundColor}>
        <p className="title">
            <span>{text}</span>
        </p>
    </StyeldContents>
);

export default InsertTitle;
