import React, { useEffect } from 'react';
import styled from 'styled-components';
import ApplyForm from './ApplyForm';
import PrivacyPolicy from './PrivacyPolicy';
import { ServiceTabProps } from './ServiceSection';

const FlexBox = styled.div`
    display: flex;
    justify-content: space-between;
`;
const FlexLeft = styled.div``;
const FlexRight = styled.div``;

const ApplyTab = ({
    selectedArt,
    Display,
    onClickCancel,
    handleLocalstorage,
}: ServiceTabProps) => {
    useEffect(() => {
        handleLocalstorage && handleLocalstorage();
    }, [selectedArt]);

    return (
        <FlexBox>
            <FlexLeft>
                {Display}
                <PrivacyPolicy fullWidth={false} />
            </FlexLeft>
            <FlexRight>
                <ApplyForm parentTab={'apply'} onClickCancel={onClickCancel} />
            </FlexRight>
        </FlexBox>
    );
};

export default ApplyTab;
