import React from 'react';
import styled from 'styled-components';
import fare_img from '../../assets/rental/fare_img.jpg';

const FareWrapper = styled.div`
    margin-top: 80px;
    table {
        width: 100%;
        border: 2px solid #d6d6d6;
        margin-top: 30px;
    }
    caption {
        visibility: hidden;
        width: 0;
        height: 0;
        line-height: 0;
        font-size: 0;
        overflow: hidden;
    }
    colgroup {
        display: table-column-group;
    }
    th {
        font-size: 15px;
        background-color: #e1e1e1;
        color: #000;
        vertical-align: middle;
        border-top: 1px solid #d6d6d6;
    }
    td {
        text-align: center;
        vertical-align: middle;
        border-top: 1px solid #d6d6d6;
        border-left: 1px solid #d6d6d6;
        padding: 15px 0;
        font-weight: 700;
    }
    td.point {
        font-size: 14px;
        background-color: #f8f6f4;
        color: #444;
    }
    .fare_image {
        position: relative;
        width: 100%;
    }
    .fare_image p {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 99;
    }
    img {
        width: 100%;
    }
`;

const Fare = () => (
    <FareWrapper>
        <div className="cont_area" style={{ marginTop: '80px' }}>
            <h4>스퀘어팩토리 요금안내</h4>
            <table>
                <caption>제품상세페이지</caption>
                <colgroup>
                    <col width="*" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                    <col width="12.5%" />
                </colgroup>
                <tbody>
                    <tr>
                        <th rowSpan={2}>작품크기</th>
                        <td className="point">~ 10 호</td>
                        <td className="point">~ 20 호</td>
                        <td className="point">~ 30 호</td>
                        <td className="point">~ 50 호</td>
                        <td className="point">~ 80 호</td>
                        <td className="point">~ 100 호</td>
                    </tr>
                    <tr>
                        <td>53.0 X 45.5 Cm</td>
                        <td>72.7 X 60.6 Cm</td>
                        <td>90.9 X 72.7 Cm</td>
                        <td>116.7 X 90.9 Cm</td>
                        <td>145.5 X 112.1 Cm</td>
                        <td>162.1 X 130.3 Cm</td>
                    </tr>
                    <tr>
                        <th>렌탈요금 (월)</th>
                        <td>3 만원 ~</td>
                        <td>6 만원 ~</td>
                        <td>9 만원 ~</td>
                        <td>15 만원 ~</td>
                        <td>20 만원 ~</td>
                        <td>25 만원 ~</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="fare_image">
            <p>
                * 렌탈 요금 기준의 예시로 정확한 가격은 각 작품마다 표기되어
                있습니다.
                <br />* VAT는 별도이며, 운송 및 설치 시 전문가의 도움은 비용이
                발생하는 선택사항입니다.
            </p>
            <img src={fare_img} alt="fare_img" />
        </div>
    </FareWrapper>
);

export default Fare;
