import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { IArt } from "../../types/state";
import { saleStatueText } from "../common/lib";

interface GalleryItemProps {
  art: IArt;
  updateClickedImage: (art: IArt) => void;
}

const StyledItem = styled.li`
  background-color: #eee;
  margin: 0 0 1em;
  width: 100%;
  display: inline-block;
`;

const ItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const StyledOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const StyledOptionItem = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledOptionItemSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const serverBaseUrl =
  process.env.REACT_APP_SERVER_BASE_URL || "https://squarefactory.co.kr";
const serverPort = process.env.REACT_APP_SERVER_PORT || "3000";

const GalleryItem = ({ art, updateClickedImage }: GalleryItemProps) => {
  return (
    <StyledItem>
      <ItemWrapper>
        <StyledImage
          src={`${serverBaseUrl}:${serverPort}/${art.imageThumbnailUrl}`}
          alt={"thumbnailImage"}
        />
        <StyledOptionContainer>
          <StyledOptionItem>
            <Typography sx={{ pb: 1, fontWeight: 700 }}>{art.title}</Typography>
          </StyledOptionItem>
          <StyledOptionItem>
            <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
              {art.author}
            </Typography>
          </StyledOptionItem>
          <StyledOptionItem>
            <Typography sx={{ pb: 1, fontSize: 14 }}>
              {art.material}
              {art.size}. {art.productionYear}
            </Typography>
          </StyledOptionItem>
          <StyledOptionItemSpaceBetween>
            <Typography sx={{ fontSize: 15, fontWeight: 600 }}>
              작품코드: {art.id}
            </Typography>
            <Typography
              color={art.salesStatus !== 2 ? "#000" : "#d3d3d3"}
              sx={{ fontSize: 14, fontWeight: 600 }}
            >
              {saleStatueText[(art.salesStatus && art.salesStatus - 1) || 0]}
            </Typography>
          </StyledOptionItemSpaceBetween>
        </StyledOptionContainer>
      </ItemWrapper>
    </StyledItem>
  );
};

export default GalleryItem;
