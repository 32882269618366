import { MeshProps } from "@react-three/fiber";
import { atom } from "recoil";
import { Euler, Vector3 } from "three";
import { IArt } from "../types/state";

export const artForm: IArt = {
  id: 0,
  imageUrl: "",
  author: "",
  writerHistory: "",
  title: "",
  size: "",
  description: "",
  details: "",
  material: "",
  productionYear: 0,
  guidance: "",
  price: 0,
  rentalPrice: 0,
  salesStatus: 0,
  subject: "",
  subjectOther: "",
  genre: "",
  genreOther: "",
  feeling: "",
  feelingOther: "",
  imageThumbnailUrl: "",
};

export const updatedTmpArts: IArt[] = [
  {
    id: 1,
    imageUrl: "https://via.placeholder.com/300x300.png",
    author: "John Doe",
    writerHistory: "John Doe is a famous artist.",
    title: "The Art of John Doe",
    size: "53.0x45.5",
    description: "A beautiful painting",
    details: "A painting by John Doe",
    material: "Canvas and oil paint",
    productionYear: 2020,
    guidance: "Handmade",
    price: 100.0,
    rentalPrice: 20.0,
    salesStatus: 1,
    subject: "landscape portrait",
    subjectOther: "",
    genre: "westernPainting sculpture/Craft",
    genreOther: "",
    feeling: "classic mysterious lightness",
    feelingOther: "",
    imageThumbnailUrl: "https://via.placeholder.com/100x100.png",
  },
  {
    id: 2,
    imageUrl: "https://via.placeholder.com/300x300.png",
    author: "Jane Doe",
    writerHistory: "Jane Doe is a well-known artist.",
    title: "The Art of Jane Doe",
    size: "53.0x45.5",
    description: "A stunning painting",
    details: "A painting by Jane Doe",
    material: "Canvas and acrylic paint",
    productionYear: 2018,
    guidance: "Handmade",
    price: 200.0,
    rentalPrice: 40.0,
    salesStatus: 1,
    subject: "landscape",
    subjectOther: "",
    genre: "popArt/illustration",
    genreOther: "",
    feeling: "comfort",
    feelingOther: "",
    imageThumbnailUrl: "https://via.placeholder.com/100x100.png",
  },
  {
    id: 3,
    imageUrl: "https://via.placeholder.com/300x300.png",
    author: "Jane Doe",
    writerHistory: "Jane Doe is a well-known artist.",
    title: "The Art of Jane Doe",
    size: "53.0x45.5",
    description: "A stunning painting",
    details: "A painting by Jane Doe",
    material: "Canvas and acrylic paint",
    productionYear: 2018,
    guidance: "Handmade",
    price: 200.0,
    rentalPrice: 40.0,
    salesStatus: 1,
    subject: "landscape",
    subjectOther: "",
    genre: "popArt/illustration",
    genreOther: "",
    feeling: "comfort",
    feelingOther: "",
    imageThumbnailUrl: "https://via.placeholder.com/100x100.png",
  },
  {
    id: 4,
    imageUrl: "https://via.placeholder.com/300x300.png",
    author: "Jane Doe",
    writerHistory: "Jane Doe is a well-known artist.",
    title: "The Art of Jane Doe",
    size: "53.0x45.5",
    description: "A stunning painting",
    details: "A painting by Jane Doe",
    material: "Canvas and acrylic paint",
    productionYear: 2018,
    guidance: "Handmade",
    price: 200.0,
    rentalPrice: 40.0,
    salesStatus: 1,
    subject: "landscape",
    subjectOther: "",
    genre: "popArt/illustration",
    genreOther: "",
    feeling: "comfort",
    feelingOther: "",
    imageThumbnailUrl: "https://via.placeholder.com/100x100.png",
  },
  {
    id: 5,
    imageUrl: "https://via.placeholder.com/300x300.png",
    author: "Jane Doe",
    writerHistory: "Jane Doe is a well-known artist.",
    title: "The Art of Jane Doe",
    size: "53.0x45.5",
    description: "A stunning painting",
    details: "A painting by Jane Doe",
    material: "Canvas and acrylic paint",
    productionYear: 2018,
    guidance: "Handmade",
    price: 200.0,
    rentalPrice: 40.0,
    salesStatus: 1,
    subject: "landscape",
    subjectOther: "",
    genre: "popArt/illustration",
    genreOther: "",
    feeling: "comfort",
    feelingOther: "",
    imageThumbnailUrl: "https://via.placeholder.com/100x100.png",
  },
  {
    id: 6,
    imageUrl: "https://via.placeholder.com/300x300.png",
    author: "Jane Doe",
    writerHistory: "Jane Doe is a well-known artist.",
    title: "The Art of Jane Doe",
    size: "53.0x45.5",
    description: "A stunning painting",
    details: "A painting by Jane Doe",
    material: "Canvas and acrylic paint",
    productionYear: 2018,
    guidance: "Handmade",
    price: 200.0,
    rentalPrice: 40.0,
    salesStatus: 1,
    subject: "landscape",
    subjectOther: "",
    genre: "popArt/illustration",
    genreOther: "",
    feeling: "comfort",
    feelingOther: "",
    imageThumbnailUrl: "https://via.placeholder.com/100x100.png",
  },
  {
    id: 7,
    imageUrl: "https://via.placeholder.com/300x300.png",
    author: "Jane Doe",
    writerHistory: "Jane Doe is a well-known artist.",
    title: "The Art of Jane Doe",
    size: "53.0x45.5",
    description: "A stunning painting",
    details: "A painting by Jane Doe",
    material: "Canvas and acrylic paint",
    productionYear: 2018,
    guidance: "Handmade",
    price: 200.0,
    rentalPrice: 40.0,
    salesStatus: 1,
    subject: "landscape",
    subjectOther: "",
    genre: "popArt/illustration",
    genreOther: "",
    feeling: "comfort",
    feelingOther: "",
    imageThumbnailUrl: "https://via.placeholder.com/100x100.png",
  },
  {
    id: 8,
    imageUrl: "https://via.placeholder.com/300x300.png",
    author: "Jane Doe",
    writerHistory: "Jane Doe is a well-known artist.",
    title: "The Art of Jane Doe",
    size: "53.0x45.5",
    description: "A stunning painting",
    details: "A painting by Jane Doe",
    material: "Canvas and acrylic paint",
    productionYear: 2018,
    guidance: "Handmade",
    price: 200.0,
    rentalPrice: 40.0,
    salesStatus: 1,
    subject: "landscape",
    subjectOther: "",
    genre: "popArt/illustration",
    genreOther: "",
    feeling: "comfort",
    feelingOther: "",
    imageThumbnailUrl: "https://via.placeholder.com/100x100.png",
  },
  {
    id: 9,
    imageUrl: "https://via.placeholder.com/300x300.png",
    author: "Jane Doe",
    writerHistory: "Jane Doe is a well-known artist.",
    title: "The Art of Jane Doe",
    size: "53.0x45.5",
    description: "A stunning painting",
    details: "A painting by Jane Doe",
    material: "Canvas and acrylic paint",
    productionYear: 2018,
    guidance: "Handmade",
    price: 200.0,
    rentalPrice: 40.0,
    salesStatus: 1,
    subject: "landscape",
    subjectOther: "",
    genre: "popArt/illustration",
    genreOther: "",
    feeling: "comfort",
    feelingOther: "",
    imageThumbnailUrl: "https://via.placeholder.com/100x100.png",
  },
];

export const selectedArt = atom<IArt | null>({
  key: "selectedArt",
  default: null,
});

export const artList = atom<IArt[]>({
  key: "artList",
  default: [artForm],
});

export const loadingAtom = atom<boolean>({
  key: "loadingAtom",
  default: false,
});

/**
 * DOM overlay 가 등록될 때 조절해야 할 클래스입니다.
 * {
 *  queryselector: string,
 *  add: Array<string>,
 *  remove: Array<string>
 * }
 */

interface IClassByQuery {
  querySelector: string;
  add: Array<string>;
  remove: Array<string>;
}

export const classByQuery = atom<Array<IClassByQuery>>({
  key: "classList",
  default: [],
});

export const customMesh = atom<MeshProps>({
  key: "customMesh",
  default: {
    position: new Vector3(),
    rotation: new Euler(),
    scale: new Vector3(),
  },
});

export const imageMeshDelta = atom<MeshProps>({
  key: "imageMeshDelta",
  default: {
    position: new Vector3(),
    rotation: new Euler(),
    scale: new Vector3(),
  },
});

export const hitTestMode = atom<Boolean>({
  key: "hitTestMode",
  default: true,
});
