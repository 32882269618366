import React, { useEffect } from "react";
import HGroup from "../components/common/HGroup";
import Location from "../components/common/Location";
import SpecificArt from "../components/gallery/SpecificArt";
import GalleryList from "../components/gallery/GalleryList";
import PageTemplate from "../templates/PageTemplate";
import { useNavigate, useParams } from "react-router-dom";
import {
  artList as artListRecoil,
  selectedArt as selectedArtRecoil,
} from "../recoil/state";
import { IArt } from "../types/state";
import { useRecoilState } from "recoil";

interface GalleryPageProps {
  domOverlay: Element;
}

const GalleryPage = ({ domOverlay }: GalleryPageProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const [artList, setArtList] = useRecoilState(artListRecoil);
  const [selectedArt, setSelectedArt] = useRecoilState(selectedArtRecoil);

  useEffect(() => {
    // onhashchange
    const currentArt =
      params.id != null
        ? artList.find(({ id }) => id.toString() === params.id)
        : null;
    setSelectedArt(currentArt === undefined ? null : currentArt);
  }, [params.id]);

  const updateClickedImage = (newSelectedArt: IArt | null) => {
    newSelectedArt == null && navigate(-1);
  };

  return (
    <PageTemplate>
      <Location path={["Home", "Gallery"]} />
      <HGroup h3="gallery" p="Premium ART Rental Service" />
      <div className="contents">
        {!selectedArt ? (
          <GalleryList
            arts={artList ? artList : undefined}
            updateClickedImage={updateClickedImage}
          />
        ) : (
          <SpecificArt
            art={selectedArt}
            domOverlay={domOverlay}
            updateClickedImage={updateClickedImage}
          />
        )}
      </div>
    </PageTemplate>
  );
};

export default GalleryPage;
