import React from 'react';
import styled from 'styled-components';
import media, { mediaQuery } from '../../styles/media';
import { ServiceProps } from './HorizService';

const StyledService = styled.li`
    width: 95%;
    max-width: 1030px;
    margin: 30px auto;
    background-color: #fff;
    padding: 20px 80px;
    box-shadow: 0px 0px 6px 3px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0px 0px 6px 3px rgb(0 0 0 / 10%);
    -moz-box-shadow: 0px 0px 6px 3px rgba(0, 0, 0, 0.1);

    display: flex;
    justify-content: flex-start;
    align-items: center;
    ${media.small} {
        flex-wrap: wrap;
    }

    h3 {
        width: 200px;
        font-size: 18px;
        color: #000;
        font-weight: 600;
        text-align: center;
        ${media.small} {
            text-align: start;
        }
    }
    p {
        margin-top: 5px;
        padding-left: 20px;
        font-size: 15px;
        color: #585858;
        width: 100%;
        ${media.small} {
            padding-left: 0;
        }
    }
`;

const VertService = ({ img, title, content }: ServiceProps) => (
    <StyledService>
        <img src={img} alt="VertService image" />
        <h3>{title}</h3>
        <p>{content}</p>
    </StyledService>
);

export default VertService;
