import React from 'react';
import styled from 'styled-components';

interface HorizServicesProps {
    children?: React.ReactNode | React.ReactNode[];
}

const StyledServices = styled.ul`
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-top: 10%;
    align-items: flex-start;
`;

const HorizServices = ({ children }: HorizServicesProps) => (
    <StyledServices>{children}</StyledServices>
);

export default HorizServices;
