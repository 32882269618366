import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ARSessions from "../../xr/ARSessions";
import styled from "styled-components";
import media from "../../styles/media";
import { purchaseArt } from "../../apis/gallery";
import { saleStatueText } from "../common/lib";
import { IArt } from "../../types/state";
import {
  translateObj,
  translatedFeeling,
  translatedGenre,
  translatedSubject,
} from "../../util/translator";

interface SpecificArtProps {
  art: IArt;
  domOverlay: Element;
  updateClickedImage: (imageUrl: null) => void;
}

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  ${media.small} {
    flex-direction: column;
    img {
      width: 100%;
      height: 100%;
    }
  }
  table {
    margin-left: 0;
  }
`;

const InfoBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 5%;
  border: 1px solid #d3d3d3;
  background-color: #eee;
`;

const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
`;

const PurchaseSubmitButton = styled.div`
  border: 1px solid black;
  width: 100%;
  margin: 0px 2px;
  padding: 4px 12px;
  background-color: ${(props) =>
    props.itemType === "white" ? "white" : "black"};
  color: ${(props) => (props.itemType === "white" ? "black" : "white")};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: #d3d3d3;
  }
`;
const isAvailable = () => {
  const WEBXR_SUPPORTED = navigator.xr && navigator.xr.isSessionSupported;
  if (!WEBXR_SUPPORTED) return Promise.resolve(false);
  return navigator.xr?.isSessionSupported("immersive-ar");
};

const serverBaseUrl =
  process.env.REACT_APP_SERVER_BASE_URL || "https://squarefactory.co.kr";
const serverPort = process.env.REACT_APP_SERVER_PORT || "3000";

const SpecificArt = ({
  art,
  domOverlay,
  updateClickedImage,
}: SpecificArtProps) => {
  const handleGoBack = useCallback(() => updateClickedImage(null), []);
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    isAvailable()
      ?.then((isSupported) => {
        if (isSupported) {
          setIsSupported(true);
        } else {
          setIsSupported(false);
        }
      })
      .catch((e) => console.error(e));
  }, []);

  const colums = {
    작가명: art.author,
    사이즈: art.size,
    재료: art.material,
    제작년도: `${art.productionYear}년`,
    구매가격: `${art.price?.toLocaleString()}원`,
    렌탈요금: `${art.rentalPrice?.toLocaleString()}원`,
    작가약력: art.writerHistory,
    상세정보: art.details,
    장르: translateObj(translatedGenre, art.genre),
    느낌: translateObj(translatedFeeling, art.feeling),
    테마: translateObj(translatedSubject, art.subject),
  };

  return (
    <Paper
      sx={{
        margin: "0 auto 30px auto",
        height: "100%",
        width: {
          xs: "100%",
          md: "90%",
        },
        maxWidth: "800px",
        padding: "0px 32px 62px 32px",
        position: "relative",
      }}
    >
      <IconButton onClick={handleGoBack}>
        <ArrowBackIcon />
      </IconButton>
      {/* <Button
        onClick={() => {
          deleteArt([art.id]);
        }}
        sx={{
          position: "absolute",
          top: "-50px",
          right: "10px",
        }}
      >
        <DeleteIcon />
      </Button> */}
      <FlexContainer>
        <img
          src={`${serverBaseUrl}:${serverPort}/${art.imageThumbnailUrl}`}
          alt="image"
          style={{
            width: "50%",
            height: "50%",
            flexGrow: 0.5,
            marginRight: "32px",
          }}
        />
        <div style={{ width: "100%" }}>
          <InfoBox>
            <Typography fontSize={14} fontWeight={600}>
              작품코드: {art.id}
            </Typography>
            <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
              {saleStatueText[(art.salesStatus && art.salesStatus - 1) || 0]}
            </Typography>
          </InfoBox>
          <Table
            sx={{
              height: "100%",
              width: "100%",
              marginLeft: { sm: "5%", xs: 0 },
              marginBottom: "10px",
              flexGrow: 0.5,
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography fontWeight={600}>{art.title}</Typography>{" "}
                </TableCell>
              </TableRow>
              {Object.entries(colums).map((v, i: number) => (
                <TableRow key={i}>
                  <TableCell
                    variant="head"
                    sx={{ fontWeight: 700, minWidth: 100 }}
                  >
                    {v[0]}
                  </TableCell>
                  <TableCell>{v[1]}</TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell colSpan={2} sx={{ borderBottom: 0 }}>
                  <ButtonBox>
                    <PurchaseSubmitButton itemType={"white"}>
                      렌탈/구매 신청
                    </PurchaseSubmitButton>
                    <PurchaseSubmitButton itemType={"black"}>
                      문의하기
                    </PurchaseSubmitButton>
                  </ButtonBox>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </FlexContainer>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          justifyContent: "flex-start",
          marginTop: "10px",
        }}
      >
        <Button
          sx={{
            width: 120,
            height: 50,
            backgroundImage: "url('../../tmpImages/kakaopay.png')",
          }}
          onClick={() => purchaseArt(art.id.toString(), 10000)}
          //금액자리는 나중에 DB에서 어떻게 쓸지몰라 하드코딩했어요.
        />
        {/* <Payment art={art} /> */}
      </div>
      {isSupported && (
        <ARSessions root={domOverlay} imageUrl={art.imageThumbnailUrl} />
      )}
    </Paper>
  );
};

export default SpecificArt;
