import React from 'react';
import HGroup from '../components/common/HGroup';
import Location from '../components/common/Location';
import PageTemplate from '../templates/PageTemplate';
import ContentsWrap from '../components/common/ContentsWrap';
import { StyledApplyForm } from '../components/service/ApplyForm';
import PrivacyPolicy from '../components/service/PrivacyPolicy';

const ContactPage = (props: any) => (
    <PageTemplate>
        <Location path={['Home', 'Contact Us']} />
        <HGroup h3="contact" p="Premium ART Rental Service" />
        <ContentsWrap>
            <StyledApplyForm parentTab={'contact'}>
                {/* <form name="frm" id="frm" method="post" action="inquiry_post.php" /> */}
                <div className="cont_area" style={{ marginTop: '0px' }}>
                    <h5>문의사항</h5>
                    <p className="infoTxt">
                        * 문의서를 작성하시면 메일로 답변해 드립니다.
                    </p>
                    <table className="boardWrite">
                        <caption>대여 / 구매 신청서 작성하기</caption>
                        <colgroup>
                            <col width="20%" />
                            <col width="80%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th scope="row">
                                    <label htmlFor="prsn_title">제목</label>
                                </th>
                                <td>
                                    <input
                                        type="text"
                                        id="prsn_title"
                                        name="prsn_nm"
                                        maxLength={50}
                                        className="mr50"
                                        style={{ width: '60%' }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <label htmlFor="email01">이메일</label>
                                </th>
                                <td className="pt10">
                                    <input
                                        type="text"
                                        id="email01"
                                        name="email01"
                                        className="mr5"
                                    />
                                    <span className="mr5">@</span>
                                    <input
                                        type="text"
                                        id="email02"
                                        name="email02"
                                        className="mr5"
                                    />
                                    <select
                                        id="email03"
                                        name="email03"
                                        style={{ width: '160px' }}
                                    >
                                        <option value="" selected={true}>
                                            직접입력
                                        </option>
                                        <option value="chol.com">
                                            chol.com
                                        </option>
                                        <option value="dreamwiz.com">
                                            dreamwiz.com
                                        </option>
                                        <option value="empal.com">
                                            empal.com
                                        </option>
                                        <option value="freechal.com">
                                            freechal.com
                                        </option>
                                        <option value="gmail.com">
                                            gmail.com
                                        </option>
                                        <option value="hanafos.com">
                                            hanafos.com
                                        </option>
                                        <option value="hanmail.net">
                                            hanmail.net
                                        </option>
                                        <option value="hanmir.com">
                                            hanmir.com
                                        </option>
                                        <option value="hitel.net">
                                            hitel.net
                                        </option>
                                        <option value="hotmail.com">
                                            hotmail.com
                                        </option>
                                        <option value="korea.com">
                                            korea.com
                                        </option>
                                        <option value="kornet.net">
                                            kornet.net
                                        </option>
                                        <option value="lycos.co.kr">
                                            lycos.co.kr
                                        </option>
                                        <option value="nate.com">
                                            nate.com
                                        </option>
                                        <option value="netian.com">
                                            netian.com
                                        </option>
                                        <option value="naver.com">
                                            naver.com
                                        </option>
                                        <option value="paran.com">
                                            paran.com
                                        </option>
                                        <option value="yahoo.com">
                                            yahoo.com
                                        </option>
                                        <option value="yahoo.co.kr">
                                            yahoo.co.kr
                                        </option>
                                    </select>
                                    <span className="txt">
                                        입력하시는 이메일로 답변을 드릴 수
                                        있으므로 정확히 입력하여 주시기
                                        바랍니다.
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <label htmlFor="cnts">내용</label>
                                </th>
                                <td>
                                    <textarea
                                        id="cnts"
                                        name="cnts"
                                        // rows="*"
                                        // cols="*"
                                        style={{ height: '197px' }}
                                    ></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </StyledApplyForm>
            <PrivacyPolicy fullWidth={true} />
        </ContentsWrap>
    </PageTemplate>
);

export default ContactPage;
