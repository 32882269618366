interface Columns {
  [key: string]: boolean;
}

export const findTrueObj = (checkedColums: Columns, column: string) => {
  const artSubjects = column.split(" ");
  for (const key of artSubjects) {
    if (key in checkedColums) {
      checkedColums[key as keyof typeof checkedColums] = true;
    }
  }
  return checkedColums;
};

export const findTrueString = (checkedColums: Columns) => {
  let resultsString = "";
  for (const key in checkedColums) {
    if (checkedColums[key as keyof typeof checkedColums] === true) {
      resultsString += key + " ";
    }
  }
  return resultsString;
};
