import React from 'react';
import { useParams } from 'react-router-dom';
import HGroup from '../components/common/HGroup';
import Location from '../components/common/Location';
import ServiceSection from '../components/service/ServiceSection';
import TabMenu from '../components/service/TabMenu';
import PageTemplate from '../templates/PageTemplate';

const ServicePage = () => {
    const { serviceName } = useParams();

    return (
        <PageTemplate>
            <Location path={['Home', 'RentalㆍPurchase']} />
            <HGroup h3="RENTAL / PURCHASE" p="Premium ART Rental Service" />
            <TabMenu serviceName={serviceName! as string} />
            <ServiceSection serviceName={serviceName! as string} />
        </PageTemplate>
    );
};

export default ServicePage;
