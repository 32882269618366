import { translateObj, transltedColumn } from "./translator";
import { IFormObj } from "./util";

const required = [
  "title",
  "author",
  "writerHistory",
  "description",
  "material",
  "productionYear",
  "price",
  "rentalPrice",
  "size",
  "image",
  "subject",
  "genre",
  "feeling",
  "salesStatus",
];

export const artValidator = (artForm: IFormObj) => {
  for (let key in artForm) {
    // productionYear의 경우 '-'값이 들어오므로 정규표현식으로 제거합니다.
    if (key === "productionYear") {
      artForm[key] = (artForm[key]! as string).replace(/-/g, "");
    }
    if (
      required.includes(key) &&
      (artForm[key] === "" || artForm[key] === "0")
    ) {
      // 빈 속성명을 에러로 던져서 catch문에서 alrert를 띄웁니다.
      throw new Error(translateObj(transltedColumn, key));
    }
  }
};
