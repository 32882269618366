import React from "react";
import {
  Card as MUICard,
  CardMedia,
  CardContent,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { IArt } from "../../types/state";
import { createImageUrl } from "../../util/util";

const StyledCard = styled(MUICard)`
  width: 300px;
  height: 400px;
  margin: 10px;
  display: inline-block;
`;

const StyledCardMedia = styled(CardMedia)`
  height: 200px;
`;

const Card: React.FC<{ artData: IArt }> = ({ artData }) => {
  return (
    <StyledCard>
      <StyledCardMedia
        image={createImageUrl(artData.imageThumbnailUrl)}
        title={artData.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {artData.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {artData.author}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {artData.description}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default Card;
