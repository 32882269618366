export const updateClassList = (
  querySelector: string,
  className: string,
  action: "ADD" | "REMOVE"
) => {
  const $element = document.querySelector(querySelector)! as HTMLElement;
  if (!$element) return;
  switch (action) {
    case "ADD":
      $element.classList.add(className);
      break;
    case "REMOVE":
      $element.classList.remove(className);
      break;
    default:
  }
};
