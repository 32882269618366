import React, { useCallback } from "react";
import styled from "styled-components";
import MainButton from "../../styles/Button";
import useDelta from "../../hooks/useDelta";

const ArrowKeys = () => {
  const [updateX, updateY, updateZ, hitTestMode] = useDelta();

  const handleMoveUp = () => {
    updateY(0.05);
  };
  const handleMoveDown = () => {
    updateY(-0.05);
  };
  const handleMoveRight = () => {
    updateX(0.05);
  };
  const handleMoveLeft = () => {
    updateX(-0.05);
  };
  const handleMoveAway = () => {
    updateZ(-0.05);
  };
  const handleMoveNear = () => {
    updateZ(0.05);
  };

  const ArrowKeysComponent = useCallback(() => {
    if (!hitTestMode) {
      return (
        <KeyWrapper>
          <div className="top-panel">
            <button className="arrow-button" onClick={handleMoveUp}>
              ▲
            </button>
            <button className="arrow-button" onClick={handleMoveAway}>
              ↗
            </button>
          </div>
          <div className="middle-panel">
            <button className="arrow-button" onClick={handleMoveLeft}>
              ◀
            </button>
            <button className="arrow-button" onClick={handleMoveRight}>
              ▶
            </button>
          </div>
          <div className="bottom-panel">
            <button className="arrow-button" onClick={handleMoveNear}>
              ↙
            </button>
            <button className="arrow-button" onClick={handleMoveDown}>
              ▼
            </button>
          </div>
        </KeyWrapper>
      );
    } else {
      return <></>;
    }
  }, [hitTestMode]);

  return (
    <div className={"arrow-keys invisible"}>
      <ArrowKeysComponent />
    </div>
  );
};

const KeyWrapper = styled.div`
  height: 100%;
  max-height: 14rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .arrow-button {
    ${MainButton}
    margin-bottom: 0;
  }
  .top-panel {
    width: 11rem;
    display: flex;
    justify-content: flex-end;
  }
  .middle-panel {
    width: 11rem;
    display: flex;
    justify-content: space-between;
  }
  .bottom-panel {
    width: 11rem;
    display: flex;
    justify-content: flex-start;
  }
`;

export default ArrowKeys;
