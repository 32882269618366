import React from 'react';
import styled from 'styled-components';
import bg_loca_line from '../../assets/common/bg_loca_line.gif';

interface LocationProps {
    path: string[];
}

const StyledSection = styled.section`
    position: relative;
    height: 40px;
    background: url(${bg_loca_line}) repeat-x left bottom;
    margin: 0 auto;
`;
const SectionContents = styled.div`
    max-width: 1140px;
    margin: 0 auto;
`;
const StyledP = styled.p`
    line-height: 40px;
    text-align: right;
    font-family: 'Raleway', sans-serif;
    font-size: 11px;
    font-weight: 500;
    color: #888;
`;
const StyledSpan = styled.span`
    color: #000;
`;

const Location = ({ path }: LocationProps) => (
    <StyledSection>
        <SectionContents>
            <StyledP>
                {path.reduce(
                    (prev, curr, idx) =>
                        curr !== '' && idx !== path.length - 1
                            ? prev + curr + ' / '
                            : prev,
                    ``
                )}
                <StyledSpan>{path[path.length - 1]}</StyledSpan>
            </StyledP>
        </SectionContents>
    </StyledSection>
);

export default Location;
