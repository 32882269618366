import React, { useCallback } from "react";
import { Vector3 } from "three";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import {
  customMesh,
  hitTestMode as _hitTestMode,
  imageMeshDelta,
} from "../recoil/state";

const useDelta = () => {
  const hitTestMode = useRecoilValue(_hitTestMode);
  const delta = useRecoilCallback(
    ({ snapshot: { getLoadable } }) =>
      () =>
        getLoadable(customMesh).getValue()
  )();
  const updateDelta = useSetRecoilState(imageMeshDelta);

  const updateX = (xValue: number): void => {
    updateDelta({
      position: new Vector3(xValue, 0, 0),
    });
  };
  const updateY = (yValue: number): void => {
    updateDelta({
      position: new Vector3(0, yValue, 0),
    });
  };
  const updateZ = (zValue: number): void => {
    updateDelta({
      position: new Vector3(0, 0, zValue),
    });
  };

  return [updateX, updateY, updateZ, hitTestMode, delta] as const;
};

export default useDelta;
