import React, { useEffect, useState } from "react";
import { Container, Typography, TextField, Button } from "@mui/material";
import styled from "styled-components";
import { useAuth } from "../hooks/useAuth";
import PageTemplate from "../templates/PageTemplate";

const FormContainer = styled(Container)`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 1rem;
`;

const LoginPage = () => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const { user, login } = useAuth();

  useEffect(() => {
    if (user) {
      login({ id: user.id, password: user.password });
    }
  }, [user]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await login({ id, password });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <PageTemplate>
      <FormContainer maxWidth="sm">
        <Typography variant="h5">로그인</Typography>
        <Form onSubmit={handleLogin}>
          <TextField
            label="아이디"
            type="text"
            margin="normal"
            fullWidth
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
          <TextField
            label="비밀번호"
            type="password"
            margin="normal"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" color="primary" type="submit">
            로그인
          </Button>
        </Form>
      </FormContainer>
    </PageTemplate>
  );
};

export default LoginPage;
