import React, { ReactElement, useCallback, MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { selectedArt as selectedArtRecoil } from "../../recoil/state";
import { IArt } from "../../types/state";
import ContentsWrap from "../common/ContentsWrap";
import ApplyTab from "./ApplyTab";
import ConsultationTab from "./ConsultationTab";
import GuideTab from "./GuideTab";
import Select from "./Select";

const StyledServiceSection = styled.div`
  margin: 60px 0;
  h4 {
    text-align: center;
    color: #151515;
    font-size: 30px;
    font-weight: 400;
  }
  h5 {
    color: #151515;
    font-size: 21px;
    font-weight: 600;
    height: 50px;
  }
`;

interface ServiceSectionProps {
  serviceName: string;
}

export interface ServiceTabProps {
  selectedArt: IArt | null;
  Display?: ReactElement;
  onClickCancel?: (e: MouseEvent<HTMLElement>) => void;
  handleLocalstorage?: () => void;
}

const ServiceSection = ({ serviceName }: ServiceSectionProps) => {
  const [selectedArt, setSelectedArt] = useRecoilState(selectedArtRecoil);
  const navigate = useNavigate();

  const handleClickCancel = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      navigate(-1);
    },
    [selectedArt, navigate]
  );

  const handleLocalstorage = () => {
    const oldData = localStorage.getItem("art");
    if (!selectedArt && oldData) {
      setSelectedArt(JSON.parse(oldData));
    } else if (!oldData) {
      localStorage.setItem("art", JSON.stringify(selectedArt));
    }
  };

  const CurrentService = useCallback(() => {
    switch (serviceName) {
      case "guide":
        return <GuideTab />;
      case "apply_write":
        return (
          <ApplyTab
            selectedArt={selectedArt}
            Display={
              selectedArt ? <Select selectedArt={selectedArt} /> : <Select />
            }
            onClickCancel={handleClickCancel}
            handleLocalstorage={handleLocalstorage}
          />
        );
      case "curator":
        return (
          <ConsultationTab
            selectedArt={selectedArt}
            onClickCancel={handleClickCancel}
          />
        );
      default:
        return <GuideTab />;
    }
  }, [serviceName]);

  return (
    <ContentsWrap>
      <StyledServiceSection>
        <CurrentService />
      </StyledServiceSection>
    </ContentsWrap>
  );
};

export default ServiceSection;
