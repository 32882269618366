import React from 'react';
import Fare from './Fare';
import Guide from './Guide';

const GuideTab = () => (
    <>
        <Guide />
        <Fare />
    </>
);

export default GuideTab;
