import React from "react";
import styled from "styled-components";
import { IArt } from "../../types/state";

interface SelectProps {
  selectedArt?: IArt;
}

const SelectWrapper = styled.div`
  .title {
    width: 383px;
    height: 48px;
    line-height: 48px;
    border: 1px solid #c8c8c8;
    background-color: #e0e0e0;
    text-align: center;
  }
  .title > p {
    text-align: center;
    font-size: 18px;
    color: #000;
    font-weight: 800;
  }
  .select_cont {
    width: 383px;
    height: 150px;
    border: 1px solid #e1e1e1;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .not_selected > .select_cont {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .select_cont_flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .text {
    text-align: center;
    line-height: 70px;
    font-size: 14px;
  }
  .imgholder {
    width: 100px;
    height: 100px;
    margin: 25px;
  }
  .imgholder img {
    width: 100px;
    height: 100px;
  }
  .artist {
    font-size: 13px;
    font-weight: 600;
    color: #666;
    line-height: 1.8;
    display: block;
  }
  .info {
    font-size: 12px;
    font-weight: 400;
    color: #666;
  }
  .fareholder {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    line-height: 1.8;
    display: block;
  }
  .btnGallery {
    background-color: #a2a2a2;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    width: 160px;
    height: 45px;
  }
  .btnCurator {
    background-color: #808080;
    color: #fff;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    width: 160px;
    height: 45px;
  }
`;

const Select = ({ selectedArt }: SelectProps) => {
  return (
    <SelectWrapper>
      {selectedArt ? (
        <div className="select_gallery_wrap">
          <h5>선택한 그림</h5>
          <div className="title">
            <p>{selectedArt?.title}</p>
          </div>
          <div className="select_cont">
            <div className="imgholder">
              <img
                src={`${process.env.PUBLIC_URL}/tmpImages/${selectedArt.imageThumbnailUrl}`}
                alt={selectedArt?.title}
              />
            </div>
            <div className="flex_container">
              <div className="contholder">
                <span className="artist">{selectedArt?.author}</span>
                <span className="info">{selectedArt?.description}</span>
              </div>
              <div className="fareholder">
                <span>렌탈요금(월) : {selectedArt?.rentalPrice}만원</span>{" "}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="not_selected">
          <div className="title">
            <b>선택된 그림이 없습니다.</b>
          </div>
          <div className="select_cont">
            <div className="select_cont_flex">
              <span className="text">그림을 선택해 주세요.</span>
              <button
                title="갤러리 보러가기"
                id="galleryGoButton"
                className="btnGallery"
                // onClick="document.location.href='../gallery/gallery_list.html'"
              >
                갤러리 보러가기
              </button>
            </div>
            <div className="select_cont_flex">
              <span className="text">큐레이터와 상담해 보세요.</span>
              <button
                title="큐레이터 상담하기"
                id="curatorGoButton"
                className="btnCurator"
                // onClick="document.location.href='./curator.html'"
              >
                큐레이터 상담하기
              </button>
            </div>
          </div>
        </div>
      )}
    </SelectWrapper>
  );
};

export default Select;
