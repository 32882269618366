import React from 'react';
import styled from 'styled-components';

interface ContentsWrapProps {
    children?: React.ReactNode | React.ReactNode[];
}

const CommonStyle = styled.div`
    max-width: 1140px;
    height: 100%;
    margin: 0 auto;
`;

const ContentsWrap = ({ children }: ContentsWrapProps) => (
    <CommonStyle>{children}</CommonStyle>
);

export default ContentsWrap;
