import React from "react";
import { Canvas } from "@react-three/fiber";
import XRComponent from "./XRComponent";
import { XR } from "@react-three/xr";
import {
  useRecoilBridgeAcrossReactRoots_UNSTABLE,
  useSetRecoilState,
} from "recoil";
import { updateClassList } from "../util/element";
import { hitTestMode, loadingAtom } from "../recoil/state";

const CanvasSection = ({ imageUrl }: { imageUrl: string }) => {
  const RecoilBridge = useRecoilBridgeAcrossReactRoots_UNSTABLE();
  const setLoading = useSetRecoilState(loadingAtom);
  const setHitTest = useSetRecoilState(hitTestMode);

  return (
    <Canvas>
      <XR
        foveation={0}
        referenceSpace="local"
        onSessionStart={() => {
          updateClassList("#root", "invisible", "ADD");
          updateClassList("#root", "ovfhide", "ADD");
          updateClassList(".xr-button", "visible", "ADD");

          // updateClassList(".fix-button", "invisible", "REMOVE");
          // updateClassList(".fix-button", "visible", "ADD");
        }}
        onSessionEnd={() => {
          updateClassList("#root", "invisible", "REMOVE");
          updateClassList("#root", "ovfhide", "REMOVE");
          updateClassList(".xr-button", "visible", "REMOVE");

          updateClassList(".arrow-keys", "visible", "REMOVE");
          updateClassList(".arrow-keys", "invisible", "ADD");
          // updateClassList(".fix-button", "visible", "REMOVE");
          // updateClassList(".fix-button", "invisible", "ADD");
          updateClassList(".reset-button", "visible", "REMOVE");
          updateClassList(".reset-button", "invisible", "ADD");

          // init states
          setLoading(false);
          setHitTest(true);
        }}
      >
        <RecoilBridge>
          <XRComponent imageUrl={imageUrl} />
        </RecoilBridge>
      </XR>
    </Canvas>
  );
};

export default CanvasSection;
