import React from 'react';
import ApplyForm from './ApplyForm';
import PrivacyPolicy from './PrivacyPolicy';
import { ServiceTabProps } from './ServiceSection';

const ConsultationTab = ({ onClickCancel, selectedArt }: ServiceTabProps) => (
    <div>
        <ApplyForm onClickCancel={onClickCancel} parentTab={'consultation'} />
        <PrivacyPolicy fullWidth={true} />
    </div>
);

export default ConsultationTab;
