import React from 'react';
import styled from 'styled-components';
import guide_process from '../../assets/rental/guide_process.gif';

const GuidWrapper = styled.div`
    margin-top: 60px;
    p {
        padding: 20px 0 0 20px;
    }
    .imageContainer {
        margin-top: 40px;
    }
    img {
        width: 100%;
    }
`;

const Guide = () => (
    <GuidWrapper>
        <h4>스퀘어팩토리 이용방법</h4>
        <div className="imageContainer">
            <img src={guide_process} alt="guide_process" />
        </div>
        <p>
            ※ 장기 렌탈의 경우 3개월 마다 주기적으로 새로운 작품으로 교체하여
            드립니다.
            <br />※ 운송 및 설치 시 전문가의 도움은 비용이 발생하는
            선택사항입니다.
        </p>
    </GuidWrapper>
);

export default Guide;
