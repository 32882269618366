import React from 'react';
import styled from 'styled-components';

interface HGroupProps {
    h3: string;
    p: string;
}

const StyledHGroup = styled.hgroup`
    margin: 30px auto;
`;
const StyledH3 = styled.h3`
    line-height: 60px;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 45px;
    font-weight: 800;
    color: #303030;
    text-transform: uppercase;
`;
const StyledP = styled.p`
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    font-weight: 400;
    color: #858585;
    text-transform: uppercase;
`;

const HGroup = ({ h3, p }: HGroupProps) => (
    <StyledHGroup>
        <StyledH3>{h3}</StyledH3>
        <StyledP>{p}</StyledP>
    </StyledHGroup>
);

export default HGroup;
