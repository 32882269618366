import React from "react";
import styled from "styled-components";
import PageTemplate from "../templates/PageTemplate";
import mainVisual from "../assets/main/m_visual01.jpg";
import btnGalleryGo from "../assets/main/btn_galleryGo.png";
import media from "../styles/media";
import { useNavigate } from "react-router-dom";

const MainSlider = styled.div`
  position: relative;
  height: 700px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const BtnGallery = styled.div`
  position: absolute;
  top: 225px;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
  img:hover {
    cursor: pointer;
  }
`;
const SliderImg = styled.div`
  width: 100%;
  height: 100%;
`;

const MainContent = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0px auto;
`;
const MainBanner = styled.div`
  margin: 50px auto 0;
  ul {
    display: flex;
    width: 100%;
    height: 100%;
    ${media.small} {
      flex-direction: column;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  li {
    padding-left: 3px;
    ${media.small} {
      padding-left: 0;
    }
  }
  li:first-child {
    padding-left: 0;
  }
  li:hover {
    cursor: pointer;
  }
`;
const MainReview = styled.div`
  margin: 70px auto 0;
`;

const HomePage = (props: any) => {
  const navigate = useNavigate();

  return (
    <PageTemplate>
      <MainSlider>
        <BtnGallery
          onClick={() => {
            navigate("gallery");
          }}
        >
          <img src={btnGalleryGo} alt="btnGalleryGo" />
        </BtnGallery>
        <SliderImg>
          <img src={mainVisual} alt="" />
        </SliderImg>
      </MainSlider>
      <MainContent>
        <MainReview>{/* Review Component */}</MainReview>
      </MainContent>
    </PageTemplate>
  );
};

export default HomePage;
