export interface Obj {
  [key: string]: string;
}

export const translatedGenre = {
  westernPainting: "서양화",
  koreanPainting: "한국화",
  "popArt/illustration": "팝아트/일러스트",
  "sculpture/craft": "조각/공예",
  other: "기타",
};

export const translatedSubject = {
  landscape: "풍경",
  stillLife: "정물",
  portrait: "초상",
  animal: "동물",
  "robot/machine": "로봇/기계",
  abstract: "추상",
  other: "기타",
};
export const translatedFeeling = {
  classic: "고전적",
  modern: "현대적",
  comfort: "편안함",
  mysterious: "신비함",
  lightness: "경쾌함",
};
export const transltedColumn = {
  image: "이미지",
  author: "작가명",
  wirterHistory: "작가약력",
  title: "작품제목",
  size: "사이즈",
  description: "작품설명",
  details: "상세정보",
  material: "재료",
  productionYear: "제작년도",
  guidance: "안내문구",
  price: "구매가격",
  rentalPrice: "렌탈요금",
  salesStatus: "판매상태",
  genre: "장르",
  subject: "테마",
  feeling: "느낌",
};

export const translateObj = (obj: Obj, eng: string) => {
  const columnValues = eng.trim().split(" ");
  let ko = "";
  for (let v of columnValues) {
    console.log(v);
    ko += obj[v] + ", ";
  }
  return ko.slice(0, -2);
};
