import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { Euler, Vector3 } from "three";
import { customMesh, hitTestMode, loadingAtom } from "../../recoil/state";
import MainButton from "../../styles/Button";
import { updateClassList } from "../../util/element";

const FixButton = () => {
  const loading = useRecoilValue(loadingAtom);
  const [hitTest, setHitTest] = useRecoilState(hitTestMode);
  const imageMesh = useRecoilValue(customMesh);

  const handleClick = () => {
    if (
      JSON.stringify(imageMesh) ===
      JSON.stringify({
        position: new Vector3(),
        rotation: new Euler(),
        scale: new Vector3(),
      })
    ) {
      return;
    }
    if (hitTest) {
      // updateClassList(".fix-button", "visible", "REMOVE");
      // updateClassList(".fix-button", "invisible", "ADD");
      updateClassList(".arrow-keys", "invisible", "REMOVE");
      updateClassList(".arrow-keys", "visible", "ADD");
      updateClassList(".reset-button", "invisible", "REMOVE");
      updateClassList(".reset-button", "visible", "ADD");
      setHitTest(false);
    }
  };

  return (
    <>
      {!loading && hitTest && (
        <div className={"fix-button visible"}>
          <FixButtonComponent onClick={handleClick}>OK</FixButtonComponent>
        </div>
      )}
    </>
  );
};

const FixButtonComponent = styled.button`
  ${MainButton}
`;

export default FixButton;
