import React from 'react';
import styled from 'styled-components';
import PageTemplate from '../templates/PageTemplate';
import HorizServicesSection from '../components/about/HorizServicesSection';
import TitleSection from '../components/about/TitleSection';
import VertServicesSection from '../components/about/VertServicesSection';
import VODSection from '../components/about/VODSection';

const AboutPage = (props: any) => (
    <PageTemplate>
        <TitleSection />
        <HorizServicesSection />
        <VertServicesSection />
        <VODSection />
    </PageTemplate>
);

export default AboutPage;
