import React, {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useRecoilValue } from "recoil";
import { PlaneGeometry, Texture, TextureLoader, Vector, Vector3 } from "three";
import { customMesh } from "../../recoil/state";
import { useLoader } from "@react-three/fiber";

const Anchor = () => {
  const ref = useRef<PlaneGeometry>(null);

  useEffect(() => {
    ref.current?.rotateX(-Math.PI / 2);
  }, [ref]);

  const Mesh = useCallback((): ReactElement<
    any,
    string | JSXElementConstructor<any>
  > => {
    const { position, scale, rotation } = useRecoilValue(customMesh);
    const image = useLoader(
      TextureLoader,
      `${process.env.PUBLIC_URL}/plus-icon.png`
    );
    const mesh = (
      <mesh
        position={(position! as Vector3).clone().add(new Vector3(0, 0, -0.005))}
        scale={scale}
        rotation={rotation}
      >
        <planeGeometry ref={ref} attach="geometry" args={[0.7, 0.7]} />
        <meshBasicMaterial
          attach="material"
          map={image as Texture}
          transparent={true}
        />
      </mesh>
    );
    return mesh;
  }, []);

  return <Mesh />;
};

export default Anchor;
