import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { Buffer } from "buffer";

const serverBaseUrl =
  process.env.REACT_APP_SERVER_BASE_URL || "https://squarefactory.co.kr";
const serverPort = process.env.REACT_APP_SERVER_PORT || "3000";

const axiosConfig: AxiosRequestConfig = {
  baseURL: `${serverBaseUrl}:${serverPort}`,
};

export const client = axios.create(axiosConfig);

export const getAllArts = async () => {
  try {
    const response = await axios.get("/images", axiosConfig);
    return response.data.rows;
  } catch (err) {
    const error = err as Error | AxiosError;
    if (!axios.isAxiosError(error)) {
      // do whatever you want with native error
      throw new Error("native error");
    }
    // do what you want with your axios error
    throw new Error("axios error");
  }
};

export const createArt = async (newArt: FormData) => {
  try {
    /**
     * @todo
     * 추가된 아이템 정보를 받아와서 리턴해야함 ?
     */
    const res = await client.post("/images/upload/", newArt);
    return res?.data.rows;
  } catch (err) {
    const error = err as Error | AxiosError;
    if (!axios.isAxiosError(error)) {
      throw new Error("native error");
    }
    throw new Error("axios error");
  }
};

export const updateArt = async (id: string, newArt: FormData) => {
  try {
    const res = await client.put(`/images/${id}`, newArt);
    return res?.data.rows;
  } catch (err) {
    const error = err as Error | AxiosError;
    if (!axios.isAxiosError(error)) {
      throw new Error("native error");
    }
    throw new Error("axios error");
  }
};

export const deleteArt = async (idArray: number[]) => {
  try {
    const idString = idArray.join("|");
    const params = Buffer.from(idString).toString("base64");
    const res = await client.delete("/images/", {
      headers: { params },
    });
    return res?.data.rows;
  } catch (err) {
    try {
      const error = err as Error | AxiosError;
      if (!axios.isAxiosError(error)) {
        throw new Error("native error" + error);
      }
    } catch (error) {
      console.error(error);
    }
    throw new Error("axios error" + err);
  }
};

export const purchaseArt = async (id: string, price: number) => {
  try {
    const res = await client.post("/purchase", { id, price });
    console.log(res);
    window.open(res.data, "_blank");
  } catch (err) {
    const error = err as Error | AxiosError;
    if (!axios.isAxiosError(error)) {
      throw new Error("native error");
    }
    throw new Error("axios error");
  }
};
