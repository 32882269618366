import React from 'react';
import styled from 'styled-components';
import ContentsWrap from '../common/ContentsWrap';
import InsertTitle from './InsertTitle';

const TitleWrap = styled.div`
    h3 {
        text-align: center;
        font-family: 'Raleway', sans-serif;
        font-size: 42px;
        font-weight: 700;
        color: #333;
        text-transform: uppercase;
        margin-top: 80px;
        letter-spacing: 3px;
    }
    .and {
        color: #d86262;
    }
    .factory {
        color: #58585a;
    }
    .txt {
        font-size: 18px;
        line-height: 1.8;
        text-align: center;
        font-weight: 500;
        color: #575757;
        padding-top: 20px;
    }
`;

const TitleSection = () => (
    <ContentsWrap>
        <TitleWrap>
            <h3>
                Welcome to <span className="and">SQUARE</span>
                <span className="factory">FACTORY</span>
            </h3>
            <InsertTitle text="당신의 공간에 품격을 더하세요." />
            <p className="txt">
                당신의 공간 그리고 그림. 스퀘어팩토리는 당신의 공간에 품격을
                더해 드립니다.
            </p>
        </TitleWrap>
    </ContentsWrap>
);

export default TitleSection;
