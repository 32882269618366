import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import icon_crop from '../../assets/common/icon_crop.png';

type parentTabType = 'apply' | 'consultation' | 'contact';
interface ApplyFormProps extends StyledApplyFormProps {
    onClickCancel?: (e: MouseEvent<HTMLElement>) => void;
}
interface StyledApplyFormProps {
    parentTab: parentTabType;
}

export const StyledApplyForm = styled.div<StyledApplyFormProps>`
    ${(props) =>
        props.parentTab === 'apply'
            ? `.cont_area {    
                position: relative;
                width: 700px;
                float: right;
            }`
            : `.cont_area {    
                position: relative;
                width: 100%;
            }`}

    .cont_area h5 {
        color: #151515;
        font-size: 21px;
        font-weight: 600;
        height: 50px;
    }
    p.infoTxt {
        position: absolute;
        right: 0px;
        top: 20px;
        font-size: 14px;
        color: #444;
    }
    .boardWrite {
        width: 100%;
        border-top: 2px solid #212121;
        border-bottom: 1px solid #212121;
    }
    .boardWrite th {
        vertical-align: middle;
        font-weight: 800;
        color: #222;
        border-top: 1px solid #d9d9d9;
        padding: 20px 15px;
        text-align: left;
    }
    .boardWrite td {
        vertical-align: middle;
        border-top: 1px solid #d9d9d9;
    }
    .boardWrite td label.red {
        font-size: 14px;
        color: #bc2e2e;
        margin-left: 5px;
    }
    .boardWrite td label {
        font-weight: 800;
        color: #222;
        text-align: left;
    }
    .boardWrite input[type='text'],
    .boardWrite input[type='password'],
    .boardWrite input[type='file'] {
        height: 34px;
        text-align: left;
        background-color: #f3f3f3;
        border: 1px solid #d9d9d9;
        padding: 0 10px;
    }
    .boardWrite select {
        height: 36px;
        text-align: left;
        background-color: #f3f3f3;
        border: 1px solid #d9d9d9;
    }
    .boardWrite td span.txt {
        font-size: 11px;
        display: block;
        padding: 5px 0 10px 0;
    }
    .pt10 {
        padding-top: 10px;
    }
    .mr5 {
        margin-right: 5px;
    }
    .ml5 {
        margin-left: 5px;
    }
    .ml40 {
        margin-left: 40px;
    }
    .mr50 {
        margin-right: 50px;
    }
    .boardWrite textarea {
        width: 94%;
        text-align: left;
        background-color: #f3f3f3;
        border: 1px solid #d9d9d9;
        padding: 0 10px;
        margin: 10px 0;
    }
    .btn_wrap {
        position: relative;
        overflow: hidden;
        margin: 30px auto 0;
        text-align: center;
    }
    .btn_wrap .btnCancel {
        width: 140px;
        height: 38px;
        background: url(${icon_crop}) 0 -110px no-repeat;
    }
    .btn_wrap .btnRequest {
        width: 140px;
        height: 38px;
        background: url(${icon_crop}) 0 -150px no-repeat;
    }
    .blind {
        display: block;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: -5000px;
        text-indent: -5000px;
        height: 0 !important;
        width: 0 !important;
        font-size: 0 !important;
        line-height: 0 !important;
        padding: 0 !important;
    }
`;

const ApplyForm = ({ parentTab, onClickCancel }: ApplyFormProps) => {
    return (
        <StyledApplyForm parentTab={parentTab}>
            <form name="frm" id="frm">
                <input type="hidden" name="gidx" id="gidx" value="<?=$gidx?>" />
                <div className="cont_area">
                    <h5>
                        {parentTab === 'apply'
                            ? '렌탈 / 구매 신청'
                            : '문의사항'}
                    </h5>
                    <p className="infoTxt">
                        * 신청서를 작성하시면 서비스 진행을 도와드립니다.
                    </p>
                    <table className="boardWrite">
                        <caption>렌탈 / 구매 신청서 작성하기</caption>
                        <colgroup>
                            <col width="20%" />
                            <col width="80%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th scope="row">서비스</th>
                                <td>
                                    <input
                                        type="radio"
                                        id="chk1"
                                        name="services"
                                        value="렌탈"
                                    />
                                    <label htmlFor="chk1" className="red">
                                        렌탈하기
                                    </label>
                                    <input
                                        type="radio"
                                        id="chk2"
                                        name="services"
                                        value="구매"
                                        checked={true}
                                        className="ml20"
                                    />
                                    <label htmlFor="chk2" className="red">
                                        구매하기
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <label htmlFor="prsn_nm01">
                                        이름 / 기업명
                                    </label>
                                </th>
                                <td>
                                    <input
                                        type="text"
                                        id="prsn_nm01"
                                        name="prsn_nm01"
                                        maxLength={50}
                                        className="mr50"
                                    />
                                    <label htmlFor="prsn_nm02">담당자</label>
                                    <input
                                        type="text"
                                        id="prsn_nm02"
                                        name="prsn_nm02"
                                        maxLength={50}
                                        className="ml40"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <label htmlFor="area">지역</label>
                                </th>
                                <td>
                                    <input
                                        type="text"
                                        id="area"
                                        name="area"
                                        maxLength={50}
                                        className="mr50"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <label htmlFor="email01">이메일</label>
                                </th>
                                <td className="pt10">
                                    <input
                                        type="text"
                                        id="email01"
                                        name="email01"
                                        className="mr5"
                                    />
                                    <span className="mr5 ml5">@</span>
                                    <input
                                        type="text"
                                        id="email02"
                                        name="email02"
                                        className="mr5"
                                    />
                                    <select
                                        id="email03"
                                        name="email03"
                                        style={{ width: '160px' }}
                                    >
                                        <option value="" selected={true}>
                                            직접입력
                                        </option>
                                        <option value="chol.com">
                                            chol.com
                                        </option>
                                        <option value="dreamwiz.com">
                                            dreamwiz.com
                                        </option>
                                        <option value="empal.com">
                                            empal.com
                                        </option>
                                        <option value="freechal.com">
                                            freechal.com
                                        </option>
                                        <option value="gmail.com">
                                            gmail.com
                                        </option>
                                        <option value="hanafos.com">
                                            hanafos.com
                                        </option>
                                        <option value="hanmail.net">
                                            hanmail.net
                                        </option>
                                        <option value="hanmir.com">
                                            hanmir.com
                                        </option>
                                        <option value="hitel.net">
                                            hitel.net
                                        </option>
                                        <option value="hotmail.com">
                                            hotmail.com
                                        </option>
                                        <option value="korea.com">
                                            korea.com
                                        </option>
                                        <option value="kornet.net">
                                            kornet.net
                                        </option>
                                        <option value="lycos.co.kr">
                                            lycos.co.kr
                                        </option>
                                        <option value="nate.com">
                                            nate.com
                                        </option>
                                        <option value="netian.com">
                                            netian.com
                                        </option>
                                        <option value="naver.com">
                                            naver.com
                                        </option>
                                        <option value="paran.com">
                                            paran.com
                                        </option>
                                        <option value="yahoo.com">
                                            yahoo.com
                                        </option>
                                        <option value="yahoo.co.kr">
                                            yahoo.co.kr
                                        </option>
                                    </select>
                                    <span className="txt">
                                        입력하시는 이메일로 답변을 드릴 수
                                        있으므로 정확히 입력하여 주시기
                                        바랍니다.
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <label htmlFor="mem_tel01">전화번호</label>
                                </th>
                                <td className="pt10">
                                    <select
                                        id="mem_tel01"
                                        name="mem_tel01"
                                        style={{ width: '60px' }}
                                    >
                                        <option value="" selected={true}>
                                            선택
                                        </option>
                                        <option value="010">010</option>
                                        <option value="011">011</option>
                                        <option value="016">016</option>
                                        <option value="017">017</option>
                                        <option value="018">018</option>
                                        <option value="019">019</option>
                                        <option value="02">02</option>
                                        <option value="031">031</option>
                                        <option value="032">032</option>
                                        <option value="033">033</option>
                                        <option value="041">041</option>
                                        <option value="042">042</option>
                                        <option value="043">043</option>
                                        <option value="044">044</option>
                                        <option value="051">051</option>
                                        <option value="052">052</option>
                                        <option value="053">053</option>
                                        <option value="054">054</option>
                                        <option value="055">055</option>
                                        <option value="061">061</option>
                                        <option value="062">062</option>
                                        <option value="063">063</option>
                                        <option value="064">064</option>
                                        <option value="070">070</option>
                                        <option value="0502">0502</option>
                                        <option value="0505">0505</option>
                                        <option value="0506">0506</option>
                                    </select>
                                    <span className="mr5 ml5">-</span>
                                    <input
                                        type="text"
                                        id="mem_tel02"
                                        name="mem_tel02"
                                        className="onlyNumber"
                                        maxLength={4}
                                    />
                                    <span className="mr5 ml5">-</span>
                                    <input
                                        type="text"
                                        id="mem_tel03"
                                        name="mem_tel03"
                                        className="onlyNumber mr5"
                                        maxLength={4}
                                    />
                                    <select
                                        id="time"
                                        name="time"
                                        style={{ width: '125px' }}
                                    >
                                        <option
                                            value="연락가능시간"
                                            selected={true}
                                        >
                                            연락가능시간
                                        </option>
                                        <option value="24:00 ~ 02:00">
                                            24:00 ~ 02:00
                                        </option>
                                        <option value="02:00 ~ 04:00">
                                            02:00 ~ 04:00
                                        </option>
                                        <option value="04:00 ~ 06:00">
                                            04:00 ~ 06:00
                                        </option>
                                        <option value=">06:00 ~ 08:00">
                                            06:00 ~ 08:00
                                        </option>
                                        <option value="08:00 ~ 10:00">
                                            08:00 ~ 10:00
                                        </option>
                                        <option value="10:00 ~ 12:00">
                                            10:00 ~ 12:00
                                        </option>
                                        <option value="12:00 ~ 14:00">
                                            12:00 ~ 14:00
                                        </option>
                                        <option value="14:00 ~ 16:00">
                                            14:00 ~ 16:00
                                        </option>
                                        <option value="16:00 ~ 18:00">
                                            16:00 ~ 18:00
                                        </option>
                                        <option value="18:00 ~ 20:00">
                                            18:00 ~ 20:00
                                        </option>
                                        <option value="20:00 ~ 22:00">
                                            20:00 ~ 22:00
                                        </option>
                                        <option value="22:00 ~ 24:00">
                                            22:00 ~ 24:00
                                        </option>
                                    </select>
                                    <span className="txt">
                                        입력하시는 전화번호로 답변을 드릴 수
                                        있으므로 정확히 입력하여 주시기
                                        바랍니다. (연락가능한 시간대
                                        선택해주세요)
                                    </span>
                                </td>
                            </tr>
                            {parentTab === 'consultation' && (
                                <tr>
                                    <th scope="row">
                                        <label htmlFor="place">
                                            설치장소 및 크기
                                        </label>
                                    </th>
                                    <td>
                                        <input
                                            type="text"
                                            id="place"
                                            name="place"
                                            maxLength={100}
                                            className="mr50"
                                            style={{ width: '260px' }}
                                        />
                                        <label htmlFor="wish_style">
                                            원하는 작품 스타일
                                        </label>
                                        <input
                                            type="text"
                                            id="wish_style"
                                            name="wish_style"
                                            maxLength={100}
                                            className="ml40"
                                            style={{ width: '370px' }}
                                        />
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <th scope="row">
                                    <label htmlFor="cnts">내용</label>
                                </th>
                                <td>
                                    <textarea
                                        id="cnts"
                                        name="cnts"
                                        // rows="*"
                                        // cols="*"
                                        style={{ height: '197px' }}
                                    ></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="btn_wrap" style={{ marginBottom: '60px' }}>
                        <button
                            title="취소하기"
                            id="cancelButton"
                            className="btnCancel mr5"
                            onClick={onClickCancel}
                        >
                            <span className="blind">취소하기</span>
                        </button>
                        <button
                            title="등록하기"
                            id="requestButton"
                            className="btnRequest"
                        >
                            <span className="blind">등록하기</span>
                        </button>
                    </div>
                </div>
            </form>
        </StyledApplyForm>
    );
};
export default ApplyForm;
