import React, { useEffect } from "react";
import { useHitTest } from "@react-three/xr";
import { useRecoilState, useSetRecoilState } from "recoil";
import { customMesh, loadingAtom } from "../recoil/state";
import { Vector3, Euler } from "three";

interface HitTestProps {
  objectTextureUrl: string;
}

const HitTest = ({}: HitTestProps) => {
  const setLoading = useSetRecoilState(loadingAtom);
  const [value, updateValue] = useRecoilState(customMesh);

  useEffect(() => {
    setLoading(true);
  }, []);

  useHitTest((hitMatrix) => {
    if (!value) return;
    updateValue(() => ({
      position: new Vector3().setFromMatrixPosition(hitMatrix),
      rotation: new Euler().setFromRotationMatrix(hitMatrix),
      scale: new Vector3().setFromMatrixScale(hitMatrix),
    }));
    setLoading(false);
  });

  return (
    <mesh visible={false} position={[0, 0, 0]} rotation={[0.5, 0, 0]}>
      <ringBufferGeometry attach="geometry" args={[0.15, 0.2, 32]} />
      <meshBasicMaterial attach="material" color="#00FF00" />
    </mesh>
  );
};

export default HitTest;
