export interface IFormObj {
  [key: string]: string | Blob;
}

export const createFormData = (FormObj: IFormObj) => {
  const formData = new FormData();
  for (const key in FormObj) {
    formData.append(key, FormObj[key]);
  }
  return formData;
};

const serverBaseUrl =
  process.env.REACT_APP_SERVER_BASE_URL || "https://squarefactory.co.kr";
const serverPort = process.env.REACT_APP_SERVER_PORT || "3000";
export const createImageUrl = (imageUrl: string) => {
  const BASE_URL = `${serverBaseUrl}:${serverPort}`;
  return `${BASE_URL}/${imageUrl}`;
};
