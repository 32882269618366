import React, { useState } from "react";
import {
  TextField,
  Radio,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Paper,
} from "@mui/material";
import styled from "styled-components";
import Button from "./Button";
import { IArt } from "../../types/state";
import { findTrueObj, findTrueString } from "../../util/multiSelect";
import { createImageUrl, IFormObj } from "../../util/util";
import { useNavigate, useSearchParams } from "react-router-dom";
import { artValidator } from "../../util/validator";

const StyledPaper = styled(Paper)`
  padding: 20px;
  width: 80%;
  margin: 20px auto;
  text-align: center;
`;

const StyledFormControl = styled.form`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledFormLabel = styled(FormLabel)`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: column;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: 1rem;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 2rem;
  margin-bottom: 2rem;
`;

interface ArtFormProps {
  art: IArt;
  onCreateArt: (newArt: IFormObj) => Promise<void>;
  onUpdateArt: (id: number, updatedArt: IFormObj) => Promise<void>;
}

const ArtForm = ({ art, onCreateArt, onUpdateArt }: ArtFormProps) => {
  const navigate = useNavigate();
  const [size, setSize] = useState(art.size);
  const checkedSubject = findTrueObj(
    {
      landscape: false,
      stillLife: false,
      portrait: false,
      animal: false,
      robotMachine: false,
      abstract: false,
      other: false,
    },
    art.subject
  );
  const checkedGenre = findTrueObj(
    {
      westernPainting: false,
      koreanPainting: false,
      "popArt/illustration": false,
      "sculpture/craft": false,
      other: false,
    },
    art.genre
  );
  const checkedFeeling = findTrueObj(
    {
      classic: false,
      modern: false,
      comfort: false,
      mysterious: false,
      lightness: false,
    },
    art.feeling
  );
  const [subject, setSubject] = useState(checkedSubject);
  const [genre, setGenre] = useState(checkedGenre);
  const [feeling, setFeeling] = useState(checkedFeeling);
  const [image, setImage] = useState<File | null>(null);
  const [author, setAuthor] = useState(art.author);
  const [writerHistory, setWriterHistory] = useState(art.writerHistory);
  const [title, setTitle] = useState(art.title);
  const [salesStatus, setSalesStatus] = useState(art.salesStatus.toString());
  const [description, setDescription] = useState(art.description);
  const [details, setDetails] = useState(art.details);
  const [material, setMaterial] = useState(art.material);
  const [productionYear, setProductionYear] = useState(
    art.productionYear.toString()
  );
  const [price, setPrice] = useState(art.price);
  const [rentalPrice, setRentalPrice] = useState(art.rentalPrice);
  const [guidance, setGuidance] = useState(art.guidance);
  const [searchParams, setSearchParams] = useSearchParams();
  const artId = searchParams.get("id");

  const handleChangeImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = e.target.files?.[0];
    if (newFile) {
      setImage(newFile);
    }
  };

  const handleSize = (event: any) => {
    setSize(event.target.value);
  };

  const handleCheckbox = (name: string) => (event: any) => {
    setSubject({ ...subject, [name]: event.target.checked });
  };

  const handleGenre = (name: string) => (event: any) => {
    setGenre({ ...genre, [name]: event.target.checked });
  };

  const handleFeeling = (name: string) => (event: any) => {
    setFeeling({ ...feeling, [name]: event.target.checked });
  };

  const handleProductionYear = (newValue: string) => {
    const newValueInt = Number(newValue);
    if (newValue.trim() === "" || isNaN(newValueInt) || newValueInt < 0) {
      setProductionYear("");
    } else {
      setProductionYear(newValue);
    }
  };

  const handlePrice = (price: number) => {
    // 0001234 -> 1234
    const priceInt = parseInt(price.toString());
    if (priceInt < 0) {
      setPrice(0);
    } else {
      setPrice(priceInt);
    }
  };

  const handleRentalPrice = (rentalPrice: number) => {
    if (Number(rentalPrice) < 0) {
      setRentalPrice(0);
    } else {
      setRentalPrice(rentalPrice);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const newArtForm = {
      title,
      author,
      writerHistory,
      description,
      details,
      material,
      productionYear,
      price: price.toString(),
      rentalPrice: rentalPrice.toString(),
      guidance,
      size,
      image: image ? image : "",
      subject: findTrueString(subject),
      genre: findTrueString(genre),
      feeling: findTrueString(feeling),
      salesStatus,
    };
    try {
      if (artId == null || artId == "null") {
        /**
         * 새로운 art를 생성하는 경우 required 값들이 모두 채워져있는지 확인합니다.
         */
        artValidator(newArtForm);
        onCreateArt(newArtForm);
      } else {
        onUpdateArt(parseInt(artId), newArtForm);
      }
      navigate(-1);
    } catch (e) {
      window.alert(`${e} 값을 입력해주세요.`);
    }
  };

  return (
    <StyledPaper elevation={3}>
      <TextField
        label="작품번호(관리번호)"
        value={art.id}
        margin="normal"
        variant="outlined"
        fullWidth
        disabled
      />
      <TextField
        label="작품제목"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        margin="normal"
        variant="outlined"
        fullWidth
      />
      <TextField
        label="작가명"
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
        margin="normal"
        variant="outlined"
        fullWidth
      />
      <TextField
        label="작가약력"
        value={writerHistory}
        onChange={(e) => setWriterHistory(e.target.value)}
        margin="normal"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
      />
      <TextField
        label="작품설명"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        margin="normal"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
      />
      <TextField
        label="상세정보"
        value={details}
        onChange={(e) => setDetails(e.target.value)}
        margin="normal"
        variant="outlined"
        fullWidth
      />
      <TextField
        label="재료"
        value={material}
        onChange={(e) => setMaterial(e.target.value)}
        margin="normal"
        variant="outlined"
        fullWidth
      />
      <TextField
        label="제작년도"
        type="number"
        value={productionYear}
        onChange={(e) => {
          handleProductionYear(e.target.value);
        }}
        margin="normal"
        variant="outlined"
        fullWidth
        inputProps={{
          min: 1700,
          max: new Date().getFullYear(),
        }}
      />
      <StyledFormControl>
        <StyledFormLabel>구매가격</StyledFormLabel>
        <TextField
          type="number"
          value={price}
          onChange={(e) => handlePrice(parseInt(e.target.value))}
          label="단위: 만원"
        />
      </StyledFormControl>
      <StyledFormControl>
        <StyledFormLabel>렌탈요금</StyledFormLabel>
        <TextField
          type="number"
          value={rentalPrice}
          onChange={(e) => handleRentalPrice(parseInt(e.target.value))}
          label="단위: 만원"
        />
      </StyledFormControl>
      <StyledFormControl>
        <StyledFormLabel>안내문구</StyledFormLabel>
        <TextField
          multiline
          rows={4}
          value={guidance}
          onChange={(e) => setGuidance(e.target.value)}
        />
      </StyledFormControl>
      <StyledFormControl onSubmit={handleSubmit}>
        <StyledFormLabel>사이즈</StyledFormLabel>
        <RadioGroup
          aria-label="size"
          name="size"
          value={size}
          onChange={handleSize}
        >
          <StyledFormControlLabel
            value="1"
            control={<Radio />}
            label="10호(53.0x45.5cm)"
          />
          <StyledFormControlLabel
            value="2"
            control={<Radio />}
            label="20호(72.7X60.6cm)"
          />
          <StyledFormControlLabel
            value="3"
            control={<Radio />}
            label="30호(90.9X72.7cm)"
          />
          <StyledFormControlLabel
            value="4"
            control={<Radio />}
            label="50호(116.7X90.9cm)"
          />
        </RadioGroup>
      </StyledFormControl>
      <StyledFormControl>
        <StyledFormLabel>Image</StyledFormLabel>
        <StyledFormGroup>
          <input type="file" onChange={handleChangeImage} />
          <img
            src={
              !image
                ? art.imageThumbnailUrl //
                  ? createImageUrl(art.imageThumbnailUrl) //
                  : "https://via.placeholder.com/300x300.png" //
                : URL.createObjectURL(image)
            }
            alt="art"
            width={300}
            height={300}
          />
        </StyledFormGroup>
      </StyledFormControl>
      <StyledFormControl>
        <StyledFormLabel>테마</StyledFormLabel>
        <StyledFormGroup>
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={subject.landscape}
                onChange={handleCheckbox("landscape")}
              />
            }
            label="풍경"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={subject.stillLife}
                onChange={handleCheckbox("stillLife")}
              />
            }
            label="정물"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={subject.portrait}
                onChange={handleCheckbox("portrait")}
              />
            }
            label="인물"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={subject.animal}
                onChange={handleCheckbox("animal")}
              />
            }
            label="동물"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={subject.robotMachine}
                onChange={handleCheckbox("robotMachine")}
              />
            }
            label="로봇/기계"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={subject.abstract}
                onChange={handleCheckbox("abstract")}
              />
            }
            label="추상"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={subject.other}
                onChange={handleCheckbox("other")}
              />
            }
            label="기타"
          />
        </StyledFormGroup>
      </StyledFormControl>
      <StyledFormControl>
        <StyledFormLabel>장르</StyledFormLabel>
        <StyledFormGroup>
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={genre.westernPainting}
                onChange={handleGenre("westernPainting")}
              />
            }
            label="서양화"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={genre.koreanPainting}
                onChange={handleGenre("koreanPainting")}
              />
            }
            label="한국화"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={genre["popArt/illustration"]}
                onChange={handleGenre("popArt/illustration")}
              />
            }
            label="팝아트/일러스트"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={genre["sculpture/craft"]}
                onChange={handleGenre("sculpture/craft")}
              />
            }
            label="조각/공예"
          />
          <StyledFormControlLabel
            control={
              <Checkbox checked={genre.other} onChange={handleGenre("other")} />
            }
            label="기타"
          />
        </StyledFormGroup>
      </StyledFormControl>
      <StyledFormControl>
        <StyledFormLabel>느낌</StyledFormLabel>
        <StyledFormGroup>
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={feeling.classic}
                onChange={handleFeeling("classic")}
              />
            }
            label="고전적"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={feeling.modern}
                onChange={handleFeeling("modern")}
              />
            }
            label="현대적"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={feeling.comfort}
                onChange={handleFeeling("comfort")}
              />
            }
            label="편안함"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={feeling.mysterious}
                onChange={handleFeeling("mysterious")}
              />
            }
            label="신비함"
          />
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={feeling.lightness}
                onChange={handleFeeling("lightness")}
              />
            }
            label="경쾌함"
          />
        </StyledFormGroup>
      </StyledFormControl>
      <StyledFormControl>
        <StyledFormLabel>판매 상태</StyledFormLabel>
        <RadioGroup
          aria-label="SalesStatus"
          name="SalesStatus"
          value={salesStatus}
          onChange={(e) => setSalesStatus(e.target.value)}
        >
          <StyledFormControlLabel
            value="1"
            control={<Radio />}
            label="판매중"
          />
          <StyledFormControlLabel
            value="2"
            control={<Radio />}
            label="판매완료"
          />
          <StyledFormControlLabel
            value="3"
            control={<Radio />}
            label="렌탈중"
          />
        </RadioGroup>
      </StyledFormControl>
      <ButtonWrapper>
        <Button onClick={handleSubmit} text={"저장"} />
        <Button onClick={() => navigate(-1)} text={"취소"} />
      </ButtonWrapper>
    </StyledPaper>
  );
};

export default ArtForm;
