import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import GalleryPage from "./pages/GalleryPage";
import ServicePage from "./pages/ServicePage";
import ReaviewPage from "./pages/ReviewPage";
import ContactPage from "./pages/ContactPage";
import { useSetRecoilState } from "recoil";
import { artList } from "./recoil/state";
import { getAllArts } from "./apis/gallery";
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import { AuthorizedRoute } from "./routes/AuthorizedRoute";

function App() {
  const [domOverlay, setDomOverlay] = useState<Element>(
    document.querySelector("#root")!
  );
  const setArts = useSetRecoilState(artList);

  useEffect(() => {
    getAllArts() //
      .then(setArts);
  }, [setArts]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="about" element={<AboutPage />} />
      <Route path="gallery" element={<GalleryPage domOverlay={domOverlay} />} />
      <Route
        path="gallery/:id"
        element={<GalleryPage domOverlay={domOverlay} />}
      />
      <Route path="service/:serviceName" element={<ServicePage />} />
      <Route path="review" element={<ReaviewPage />} />
      <Route path="contact" element={<ContactPage />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="logout" element={<LogoutPage />} />
      <Route path="dashboard/*" element={<AuthorizedRoute />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
}

export default App;
