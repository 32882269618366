import React from "react";
import AdminHome from "../components/dashboard/AdminHome";
import PageTemplate from "../templates/PageTemplate";

const DashboardPage = (props: any) => (
  <PageTemplate>
    <AdminHome />
  </PageTemplate>
);

export default DashboardPage;
