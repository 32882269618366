import React, { useCallback, useEffect, useState } from "react";
import { useXR } from "@react-three/xr";
import { useRecoilState, useRecoilValue } from "recoil";
import { Object3D, Vector3 } from "three";
import HitTest from "./HitTest";
import ImageGeo from "./ImageGeo";
import { customMesh, hitTestMode, imageMeshDelta } from "../recoil/state";
import Anchor from "./domoverlay/Anchor";

const XRComponent = ({ imageUrl }: { imageUrl: string }) => {
  // histTest: false -> <HitTest>를 렌더링함으로써 useHitTest 실행 -> imgRef의 위치를 hit test 결과로 이동
  // -> 사용자 select 이벤트 발생 -> hist test 중단
  const hitTest = useRecoilValue(hitTestMode);
  const [imageRef, setImageRef] = useState<Object3D>();
  const [customMeshState, initCustomMeshState] = useRecoilState(customMesh);
  const delta = useRecoilValue(imageMeshDelta);

  const onImageRefChange = useCallback((node: Object3D | null) => {
    if (node !== null) {
      setImageRef(node);
    }
  }, []);

  // 세션 종료 이후에도 남아있는 이미지 버그 수정
  useXR(({ session }) => {
    if (!session && !hitTest) {
      if (imageRef) {
        imageRef.visible = false;
      }
    }
  });

  useEffect(() => {
    // ImageGeo 컴포넌트로 이전
    imageRef?.translateX((delta.position! as Vector3).x);
    imageRef?.translateY((delta.position! as Vector3).y);
    imageRef?.translateZ((delta.position! as Vector3).z);
  }, [delta]);

  return (
    <>
      {hitTest ? (
        <>
          <HitTest objectTextureUrl={imageUrl} />
          <Anchor />
        </>
      ) : (
        <ImageGeo
          callbackRef={onImageRefChange}
          imageUrl={imageUrl}
          {...customMeshState}
        />
      )}
    </>
  );
};

export default XRComponent;
