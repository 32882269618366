import React from 'react';
import styled from 'styled-components';

interface VertServicesProps {
    children?: React.ReactNode | React.ReactNode[];
}

const StyledServices = styled.ul`
    li:first-child {
        margin-top: 70px;
    }
`;

const VertServices = ({ children }: VertServicesProps) => (
    <StyledServices>{children}</StyledServices>
);

export default VertServices;
