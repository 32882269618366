import React from 'react';
import styled from 'styled-components';
import InsertTitle from './InsertTitle';

const VODWrap = styled.div`
    width: 100%;
    height: 720px;
    margin: 100px auto 0;
    background-color: #e7e7e7;
    text-align: center;
    padding: 60px 0;
    iframe {
        margin-top: 40px;
        max-width: 870px;
        max-height: 489px;
    }
`;

const VODSection = () => (
    <VODWrap>
        <InsertTitle text="스퀘어팩토리 홍보영상" backgroundColor="#e7e7e7" />
        <iframe
            width="80%"
            height="80%"
            src="https://www.youtube.com/embed/6ZOnTmcDY3k"
            frameBorder="0"
            allowFullScreen
        ></iframe>
    </VODWrap>
);
export default VODSection;
