import React, { useMemo } from "react";
import Masonry from "react-masonry-css";
import styled from "styled-components";
import GalleryItem from "./GalleryItem";
import { Link } from "react-router-dom";
import ManagementMode from "../ManagementMode";
import { IArt } from "../../types/state";

interface GalleryListProps {
  updateClickedImage: (art: IArt) => void;
  arts?: IArt[];
}

/**
 * @TODO
 * delete this interface and use IArt interface from src/recoil/state.ts
 */
export interface Art {
  name: string;
  url: string;
  id: string;
  title?: string;
  author?: string;
  imageThumbnailUrl?: string;
  material?: string;
  size?: string;
  productionYear?: string;
  price?: number;
  salesStatus?: number;
  writerHistory?: string;
  rentalPrice?: number;
  description?: string;
  details?: string;
}

const StyledSection = styled.section`
  position: relative;
  margin: 30px auto 0;
  min-height: 1000px;

  .my-masonry-grid {
    padding: 10px;
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }

  .my-masonry-grid_column {
    padding-left: 30px; /* gutter size */
    background-clip: padding-box;
  }
`;

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
};

const GalleryList = ({ arts, updateClickedImage }: GalleryListProps) => {
  const galleryItems = useMemo(() => {
    if (!arts || arts.length === 0) return;
    return arts.map((art) => (
      <Link to={`${art.id}`} key={Math.random()}>
        <GalleryItem art={art} updateClickedImage={updateClickedImage} />
      </Link>
    ));
  }, [arts]);

  return (
    <StyledSection>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {galleryItems ? galleryItems : "작품이 존재하지 않습니다."}
      </Masonry>
      {/* <ManagementMode /> */}
    </StyledSection>
  );
};

export default GalleryList;
