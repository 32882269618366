import React from 'react';
import styled from 'styled-components';
import HorizService from './HorizService';
import HorizServices from './HorizServices';
import bg_services from '../../assets/about/bg_services.jpg';
import services_icon01 from '../../assets/about/services_icon01.png';
import services_icon02 from '../../assets/about/services_icon02.png';
import services_icon03 from '../../assets/about/services_icon03.png';

const HorizServicesWrap = styled.div`
    width: 100%;
    // height: 500px;
    margin: 70px auto;
    background: url(${bg_services}) repeat center top;
`;

const HorizServicesSection = () => (
    <HorizServicesWrap>
        <HorizServices>
            <HorizService
                img={services_icon01}
                title="다양한 예술작품을"
                content={
                    <>
                        스퀘어팩토리에서는 주목받는 유망작가 및 원로,
                        <br />
                        중진작가의 우수한 작품을 선보입니다.
                        <br />
                        서양화와 동양화 작품에서 부터 판화, 조각, 공예
                        <br />
                        등의 예술작품을 진품으로 만나실 수 있습니다.
                    </>
                }
            />
            <HorizService
                img={services_icon02}
                title="전문가와 함께"
                content={
                    <>
                        스퀘어팩토리에는 전문큐레이터 및
                        <br />
                        국내 미술대전의 심사위원으로 구성된 자문단이 함께합니다.
                        <br />
                        전문가의 눈으로 작품을 엄선하고
                        <br />
                        미술 심리 치료사의 도움으로 취향과 공간을 고려해
                        <br />
                        작품을 추천하여드립니다.
                    </>
                }
            />
            <HorizService
                img={services_icon03}
                title="편리하고 부담없이"
                content={
                    <>
                        그동안 쉽게 다가가기 어려웠던 미술작품들...
                        <br />
                        이제 스퀘어팩토리의 미술경영전문가와 함께
                        <br />
                        이 모든 서비스를 당신의 공간에서 합리적인 가격에
                        <br />
                        부담 없이 누리실 수 있습니다.
                    </>
                }
            />
        </HorizServices>
    </HorizServicesWrap>
);

export default HorizServicesSection;
