import React from "react";
import { Grid, Checkbox } from "@mui/material";
import { AdminHomeProps } from "./AdminHome";
import Card from "./Card";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { IArt } from "../../types/state";
import { IFormObj } from "../../util/util";

const StyledGrid = styled(Grid)`
  && {
    padding: 16px;
  }
  display: flex;
  align-items: flex-start;
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    margin-right: 8px;
  }
`;

interface ArtCardListProps extends AdminHomeProps {
  handleToggle: (value: number) => () => void;
  selected: number[];
  artData: IArt[];
  onCreateArt?: (newArt: IFormObj) => Promise<void>;
  onUpdateArt?: (id: number, updatedArt: IFormObj) => Promise<void>;
  onDeleteArt?: (id: number) => Promise<void>;
}

const CardList = ({
  selected,
  artData,
  handleToggle,
  onCreateArt,
  onUpdateArt,
  onDeleteArt,
}: ArtCardListProps) => (
  <Grid container spacing={1}>
    {artData.map((art) => (
      <StyledGrid key={art.id} item xs={12} sm={7} md={4}>
        <StyledCheckbox
          onClick={handleToggle(art.id)}
          checked={selected.indexOf(art.id) !== -1}
        />
        <Link to={{ pathname: "edit", search: `?id=${art.id.toString()}` }}>
          <Card key={art.id} artData={art} />
        </Link>
      </StyledGrid>
    ))}
  </Grid>
);

export default CardList;
