import React, { useState } from "react";
import { XRButton } from "@react-three/xr";
import CanvasSection from "./CanvasSection";
import ArrowKeys from "./domoverlay/ArrowKeys";
import styled from "styled-components";
import MainButton from "../styles/Button";
import FixButton from "./domoverlay/FixButton";
import ResetButton from "./domoverlay/ResetButton";
import Loader from "../components/common/Loader";

interface ARSessionsProps {
  root: Element;
  imageUrl: string;
}

const ARSessions = ({ root, imageUrl }: ARSessionsProps) => {
  const [sessionStart, setSessionStart] = useState<boolean>(false);

  return (
    <>
      <StyledXRButton
        mode={"AR"}
        sessionInit={{
          requiredFeatures: ["hit-test"],
          optionalFeatures: ["dom-overlay"],
          domOverlay: {
            root,
          },
        }}
        className={"xr-button"}
      >
        {(status) => {
          console.log(`WebXR ${status}`);
          if (status === "entered") {
            setSessionStart(true);
            return "종료";
          } else {
            setSessionStart(false);
            return "AR";
          }
        }}
      </StyledXRButton>
      {sessionStart && (
        <>
          <CanvasSection
            imageUrl={`${process.env.PUBLIC_URL}/tmpImages/${imageUrl}`}
          />
          <DomOverlays>
            <div className="dom-overlays-flex">
              <FixButton />
              <ResetButton />
              <ArrowKeys />
            </div>
            <Loader />
          </DomOverlays>
        </>
      )}
    </>
  );
};

const StyledXRButton = styled(XRButton)`
  ${MainButton}
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 50px;
  right: 65px;
  z-index: 10000;
`;

const DomOverlays = styled.div`
  position: absolute;
  z-index: 10005;
  width: 120px;
  top: -70px;
  right: 40px;
  .dom-overlays-flex {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export default ARSessions;
