import React from 'react';
import styled from 'styled-components';
import flogoGif from '../../assets/common/f_copy.gif';

interface FooterProps {}

const StyledFooter = styled.footer`
    width: 100%;
    margin: 0 auto;
    background-color: #ececec;
    span {
        padding: 0 7.5px;
    }
    img {
        margin-bottom: 20px;
    }
`;
const StyledP = styled.p`
    text-align: center;
    color: #868686;
    font-size: 12px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const Footer = (props: FooterProps) => (
    <StyledFooter>
        <StyledP>
            <span>상호 : (주)스퀘어팩토리</span>
            <span>|</span>
            <span>사업자등록번호 : 309-88-02034</span>
            <span>|</span>
            <span>대표 : 김령희</span>
            <span>|</span>
            <span>주소 : 부산광역시 중구 영주로 91, 411호</span>
        </StyledP>
        <StyledP>
            <span>2022 ALL Rights Reserved. Designed By SquareFactory</span>
        </StyledP>
    </StyledFooter>
);

export default Footer;
